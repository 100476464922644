const DayTen = () => {
    return (
        <section id="day10" className="package-detail tabcontent">
            <h1 className="title">Day 10: Bwindi – Lake Mburo</h1>

            <p className="text">This morning you will drive to Lake Mburo having a game drive while entering the park. Lake Mburo is an extension of the Tanzanian Plains with big herds of Zebra, Impala, Eland and other savannah species. For birders, this is one of the best place to find African Fin foot, Papyrus, Bush Shrike, etc. Head to your Lodge where you will spend the night.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Rwakobo Rock Lodge</p>
                <p><b>Premium</b> : Mihingo Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayTen;