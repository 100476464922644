const DayOne = () => {
    return (
        <section id="day1" className="package-detail tabcontent">
            <h1 className="title">Day 1: Arrival – Entebbe</h1>
            <p className="text">A Hadfar Safaris representative, smart and charming will warmly welcome you upon arrival at the airport. He/She will brief you about your safari and then transfer you to your hotel.</p>
            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Airport View Hotel</p>
                <p><b>Premium</b> : Hotel No. 5</p>
                <p><b>Meal Plan</b> : Bed and Breakfast</p>
            </div>
        </section>
    );
}
 
export default DayOne;