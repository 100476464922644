import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./UG8D_GL_CHIMP/DayOne";
import DayTwo from "./UG8D_GL_CHIMP/DayTwo";
import DayThree from "./UG8D_GL_CHIMP/DayThree";
import DayFour from "./UG8D_GL_CHIMP/DayFour";
import DayFive from "./UG8D_GL_CHIMP/DayFive";
import DaySix from "./UG8D_GL_CHIMP/DaySix";
import DaySeven from "./UG8D_GL_CHIMP/DaySeven";
import DayEight from "./UG8D_GL_CHIMP/DayEight";

const UG8D_GL_CHIMP = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'},
        {id: 7, dayElement: 'day7', dayBtn: 'day7btn', text: 'day 7'},
        {id: 8, dayElement: 'day8', dayBtn: 'day8btn', text: 'day 8'}
    ];

    return (

        <main id="ug8d-gl-chimp" className="page single-package">

            <BannerArea 
                title="Uganda 8 Days Great Lakes And The Chimpazees" 
                location="Kibale Forest National Park" 
                days="8" 
                nights="7" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <DaySeven />

                <DayEight />

                <aside className="side-menu">

                    <Quote package_name="Uganda 8 days great Lakes and the Chimpazees"/>
                    
                </aside>

            </section>

        </main>
    );

}

export default UG8D_GL_CHIMP;