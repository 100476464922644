const DaySeven = () => {
    return (
        <section id="day7" className="package-detail tabcontent">
            <h1 className="title">Day 7: Bwindi – Lake Bunyonyi</h1>

            <p className="text"><b>Activities of the day: Breakfast then transfer to lake Bunyonyi</b></p>

            <p className="text">This morning, you will have breakfast and then transfer to L. Bunyonyi for two nights. Lake Bunyonyi (“Place of many little birds”) is the perfect place to stop for a break. The serene lake is rumored to be the deepest in Africa, reaching depths of 900 meters. The lake stretches around 25 km in length and 7 meters wide, with 29 islands scattered throughout. Although it can be very tempting to just sit by the water and soak up the sun, there are many different activities available, including a boat ride to the different islands, bird watching, and bike riding some of which you can do tomorrow.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Arcadia Cottages</p>
                <p><b>Premium</b> : Birdnest</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DaySeven;