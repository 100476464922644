const DayFour = () => {
    return (
        <section id="day4" className="package-detail tabcontent">
            <h1 className="title">Day 4: Kibale – Queen Elizabeth National Park.</h1>

            <p className="text">After breakfast, you will depart Kibale and then head for Queen Elizabeth National Park for two nights. Have a game drive while entering the park and later continue to your lodge.</p>

            <p className="text">Queen Elizabeth National Park, boasts one of the highest biodiversity ratings of any game park or reserve in the world. Uganda’s second largest National Park, the 1,978 sq km has everything from open savannah to rain forest. Queen Elizabeth has over 100 mammal species, and an astounding 606 bird species, making it a premier safari destination.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Duration</b>: Approx. 2 hours’ drive</p>
                <p><b>Comfort</b>: Kasenyi Safari Camp</p>
                <p><b>Premium</b>: Mweya Safari Lodge</p>
                <p><b>Meal Plan</b>: Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayFour;