const DayFour = () => {
    return (
        <section id="day4" className="package-detail tabcontent">
            <h1 className="title">Day 4: Bwindi - Queen Elizabeth National Park</h1>
            <p className="text">After breakfast, you will proceed to QENP passing via Ishasha named to be home of the tree climbing lions arriving at your lodge in the afternoon. Queen Elizabeth National Park, which was given its name to commemorate a visit by Queen Elizabeth II, is the country’s second largest National Park. At an area of approximately 1,978 sq. km, the park has diverse ecosystems including sprawling savanna, shady humid forests, sparkling lakes and fertile wetlands which make it the ideal habitat for classic big game, ten primate species including chimpanzees and over 600 species of birds. During a game drive, you will be able to spot large herds of buffalo, Ugandan kob, hippos, prides of lions, elephant and the elusive leopard among many other animals. Avid birders will be able to spot various species of birds including the Black and White Casket, Cuckoos, and Sunbirds and the Great Blue Turaco!</p>

            <p className="text">Have a game drive while entering the park and later continue to your lodge.</p>
            
            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Duration</b> : Approx 5-6 hours drive</p>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayFour;