import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./TST_OF_UG/DayOne";
import DayTwo from "./TST_OF_UG/DayTwo";
import DayThree from "./TST_OF_UG/DayThree";
import DayFour from "./TST_OF_UG/DayFour";
import DayFive from "./TST_OF_UG/DayFive";
import DaySix from "./TST_OF_UG/DaySix";
import DaySeven from "./TST_OF_UG/DaySeven";
import DayEight from "./TST_OF_UG/DayEight";
import DayNine from "./TST_OF_UG/DayNine";
import DayTen from "./TST_OF_UG/DayTen";
import DayEleven from "./TST_OF_UG/DayEleven";
import DayTwelve from "./TST_OF_UG/DayTwelve";

const TST_OF_UG = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'},
        {id: 7, dayElement: 'day7', dayBtn: 'day7btn', text: 'day 7'},
        {id: 8, dayElement: 'day8', dayBtn: 'day8btn', text: 'day 8'},
        {id: 9, dayElement: 'day9', dayBtn: 'day9btn', text: 'day 9'},
        {id: 10, dayElement: 'day10', dayBtn: 'day10btn', text: 'day 10'},
        {id: 11, dayElement: 'day11', dayBtn: 'day11btn', text: 'day 11'},
        {id: 12, dayElement: 'day12', dayBtn: 'day12btn', text: 'day 12'}
    ];

    return (

        <main id="tst-of-ug" className="page single-package">

            <BannerArea 
                title="Taste Of Uganda" 
                location="Variety in Uganda" 
                days="12" 
                nights="11" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <DaySeven />

                <DayEight />

                <DayNine />

                <DayTen />

                <DayEleven />

                <DayTwelve />

                <aside className="side-menu">

                    <Quote package_name="Taste Of Uganda"/>
                    
                </aside>

            </section>

        </main>
    );

}

export default TST_OF_UG;