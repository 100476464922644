const DayNine = () => {
    return (
        <section id="day9" className="package-detail tabcontent">
            <h1 className="title">Day 9: Gorilla Tracking – Bwindi / Mgahinga-Northern / Southern Groups</h1>

            <p className="text">This morning you will have to wake up very early to get ready for Gorilla tracking. Do not forget to take your lunch boxes and all the necessary gear to track the rare Mountain Gorilla, perhaps one of the most exciting wildlife experiences on earth. There are now only 650 left in the world! The tracking may take between 2 and 6 hours but it’s well worth the effort. There are always park guides with you who interpret the flora and fauna and the lifestyle of the Gorillas.</p>

            <p className="text">Return to the lodge in the afternoon and relax.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice / Depends on sector where you track gorillas</p>
                <p><b>Comfort</b> : Buhoma haven Lodge/ Rushaga Gorilla Camp</p>
                <p><b>Premium</b> : Mahogany Springs/ Gorilla Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayNine;