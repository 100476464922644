const Contact = () => {

    return (
        <section className="contact">
            <div className="overlay">
                <div className="wrapper">
                    <div className="info">
                        <h1 className="title">Feel free to contact us at any time for more information</h1>
                        <p className="text"><i className="icon icon-location-8"></i>Matayo Close Kyaliwajala</p>
                        <p className="text"><i className="icon icon-phone-3"></i>+256 392 965979</p>
                        <p className="text"><i className="icon icon-phone-3"></i>+256 772 857921</p>
                        <p className="text"><i className="icon icon-phone-3"></i>+256 778 028668</p>
                        <p className="text"><i className="icon icon-mail-alt"></i>info@hadfarsafaris.com</p>
                        <p className="text"><i className="icon icon-address-card-o"></i>P.O.Box 35227, Kampala</p>
                    </div>
                    <form action="/mail/mail.php" method="POST" className="card">
                        <h2>Email Us</h2>
                        
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Your Name *" required="required" />

                        <label htmlFor="subject">Subject</label>
                        <input type="text" id="subject" name="subject" placeholder="Subject" />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Your Email *" required="required" />

                        <label htmlFor="message">Message</label>
                        <textarea rows="5" cols="20" id="message" name="message" placeholder="Message *" required="required" />

                        <button type="submit" name="sendmail">Send</button>
                    </form>
                </div>
            </div>
        </section>
    );

}

export default Contact;