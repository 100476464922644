const DayTwo = () => {
    return (
        <section id="day2" className="package-detail tabcontent">
            <h1 className="title">Day 2: Entebe – Kibale Forest</h1>

            <p className="text">After breakfast, you will depart Entebbe for Kibale Forest National Park. Kibale is home to an extraordinary thirteen species of primates, including chimpanzees, red colobus, and L’Hoest monkeys. Kibale National Forest has also recorded over 355 bird species, and a range of other mammals including the elusive forest elephant.</p>

            <p className="text">Arrive Kibale in the afternoon and Rest as you prepare for tomorrow’s Chimps trek.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Duration</b> : Approx. 5-6 hours (450 km)</p>
                <p><b>Comfort</b> : Kibale Guest Cottages</p>
                <p><b>Premium</b> : Primate Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayTwo;