const DayThirteen = () => {
    return (
        <section id="day13" className="package-detail tabcontent">
            <h1 className="title">Day 13: Fly out</h1>
            <p className="text">After breakfast, you will have a city tour where you will explore Kigali, by visiting the Genocide Museum and the Caplaki Crafts Center. Later, you will be transferred to the airport to catch your flight back home.</p>

            <div className="details">
                <h3 className="small-title">Cost inclusions :</h3>
                <ul>
                    <li>All meals and accommodation as per the itinerary</li>
                    <li>All ground transportation in a comfortable 4x4 vehicle</li>
                    <li>Park entrance fees</li>
                    <li>Services of a full time English speaking driver/guide</li>
                    <li>Water, coffee or Tea while on safari.</li>
                    <li>All game drives</li>
                    <li>All airport pick-ups and drops</li>
                    <li>All activities as stipulated in the itinerary</li>
                    <li>All government taxes/levies</li>
                </ul>
            </div>

            <div className="details">
                <h3 className="small-title">Excluded are :</h3>
                <ul>
                    <li>Optional forest walks each at</li>
                    <li>Optional Golden monkey visit</li>
                    <li>Optional Ibyiwatu cultural performance in Kinigi per person</li>
                    <li>All optional activities</li>
                    <li>Phone calls and bar bills</li>
                    <li>Personal insurance</li>
                    <li>Laundry</li>
                    <li>Air fare</li>
                    <li>All other services of a personal nature</li>
                    <li>Driver guide’s gratuity</li>
                </ul>
            </div>

        </section>
    );
}
 
export default DayThirteen;