const DaySix = () => {
    return (
        <section id="day6" className="package-detail tabcontent">
            <h1 className="title">Day 6: Bwindi Impenetrable Forest National Park</h1>

            <p className="text">This morning we enter <b>Bwindi Impenetrable Forest National Park</b> - home to half the world's population of critically endangered mountain gorillas, and also a sanctuary for colobus monkeys. This dense equatorial rainforest is accessible only on foot and features one of the richest ecosystems in Africa, including 120 species of mammals, 350 bird species including hornbills and turacos, 220 butterfly species, 27 frog species as well as chameleons, geckos and many other endangered species. It also boasts over 1,000 different floral species. After walking (or driving up to 1.5-hours) to your specified gate, rangers will allocate you to a small group of up to 8 people - with each gorilla family graded as a short, medium or long hike, based on their location the previous day. We recommend you ask your guide to request the appropriate category for you, although this cannot be guaranteed as the gorillas may have moved significantly overnight. After a briefing, you will then track a family of endangered Mountain Gorillas, accompanied by rangers.</p>

            <p className="text">You can hope to view a family of these enormous primates and observe their behavior up close - a rare privilege indeed!</p>

            <p className="text">Return to your lodge and relax or take part in optional activities like a village walk, or a nature walk among others that you can pay for on ground.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your lodge</p>
                <p><b>Comfort : </b>Buhoma Haven Lodge</p>
                <p><b>Premium : </b>Mahogany Springs Lodge</p>
            </div>
        </section>
    );
}
 
export default DaySix;