const DayFourteen = () => {
    return (
        <section id="day14" className="package-detail tabcontent">
            <h1 className="title">Day 14: Murchison Falls National Park</h1>

            <p className="text"><b>Activities for the day: Morning game drive and afternoon boat trip to the bottom of the falls.</b></p>

            <p className="text">Depart for an early morning game drive across the rolling plains of Uganda’s largest National Park returning later to the lodge for breakfast. The 3,893 sq km park is home to a range of animals, and you will have a chance of viewing some of the Park’s residents including lions, giraffes, hartebeest, buffalo, and elephants. For the birders amongst you, these areas support the secretary bird and the black chested snake eagle, to name a few.</p>
            
            <p className="text">In the afternoon, go for a boat trip to the base of Murchison Falls, where the Nile thunders through a small 7 meter gap plunging 43 meters into the Nile on the way to Lake Albert. The banks of the Nile are densely populated with crocodiles, hippopotamus, and a range of other wildlife that come to the river to drink. There is also the option of getting off the boat and hiking up the side of the falls to the top.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                <p><b>Premium</b> : Paraa Safari Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayFourteen;