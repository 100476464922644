const DayFive = () => {
    return (
        <section id="day5" className="package-detail tabcontent">
            <h1 className="title">Day 5: Queen Elizabeth National Park – Bwindi Impenetrable National park</h1>
            <p className="text">Drive towards the Kigezi area to the famous Bwindi Impenetrable national Park. Bwindi Impenetrable National Park, also called “The Place of Darkness” due to its dense treetops lies on the eastern edge of the Albertine Rift Valley covering approximately 321 sq. km. Bwindi is home to almost half of the world’s mountain gorilla population. It is also a biodiversity hotspot providing shelter to a further 120 mammals, including several primate species such as baboons and chimpanzees, as well as elephants and antelopes. There are around 350 species of birds hosted in this forest, including 23 Albertine Rift endemics. You will have the chance to spot hornbills and turacos, 220 butterfly species, 27 frog species as well as chameleons, geckos and many other endangered species. Bwindi also boasts over 1,000 different flowering plants, ferns & trees.</p>

            <p className="text">Check into your lodge and enjoy your remote African surroundings.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Buhoma Haven Lodge</p>
                <p><b>Premium</b> : Mahogany Springs Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayFive;