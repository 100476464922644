import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from './partials/Header';
import Home from './Home';
import About from './About';

import Destinations from './Destinations';
import Rwanda from "./destinations/Rwanda";
import Uganda from "./destinations/Uganda";

import TourPackages from './TourPackages';
import RW3D_GR_TR from './tour_packages/RW3D_GR_TR';
import RW3D_GR_EX from './tour_packages/RW3D_GR_EX';
import UG8D_GL_CHIMP from './tour_packages/UG8D_GL_CHIMP';
import UG6D_BW_QN from './tour_packages/UG6D_BW_QN';
import UG10D_TOUR from './tour_packages/UG10D_TOUR';
import BT_SUN from './tour_packages/BT_SUN';
import SEC_OF_UG_RW from './tour_packages/SEC_OF_UG_RW';
import MCSH_FALL_SAF from './tour_packages/MCSH_FALL_SAF';
import TST_OF_UG from './tour_packages/TST_OF_UG';
import S17D_INT from './tour_packages/S17D_INT';

// import Blog from './Blog';
import Contact from './home/Contact';
import NotFound from './NotFound';
import Footer from './partials/Footer'

function App() {
  return (
    <Router>

      <div className="App">

        <Header />

        <div className="page">
          <Switch>

            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/about">
              <About />
            </Route>

            {/* Destinations Routes */}
            <Route exact path="/destinations">
              <Destinations />
            </Route>

            <Route exact path="/destinations/uganda">
              <Uganda />
            </Route>

            <Route exact path="/destinations/rwanda">
              <Rwanda />
            </Route>


            {/* Tour packages routes */}
            <Route exact path="/tour-packages">
              <TourPackages />
            </Route>
            
            <Route exact path="/tour-packages/rwanda-3-days-gorilla-tracking">
              <RW3D_GR_TR />
            </Route>

            <Route exact path="/tour-packages/uganda-8-days-great-lakes-and-the-chimpazees">
              <UG8D_GL_CHIMP />
            </Route>

            <Route exact path="/tour-packages/rwanda-uganda-3-days-gorilla-express">
              <RW3D_GR_EX />
            </Route>

            <Route exact path="/tour-packages/uganda-6-days-5-nights-bwindi-and-queen">
              <UG6D_BW_QN />
            </Route>

            <Route exact path="/tour-packages/uganda-10-days">
              <UG10D_TOUR />
            </Route>

            <Route exact path="/tour-packages/beautiful-sunshine">
              <BT_SUN />
            </Route>

            <Route exact path="/tour-packages/secrets-of-uganda-and-rwanda">
              <SEC_OF_UG_RW />
            </Route>

            <Route exact path="/tour-packages/murchison-falls-safari">
              <MCSH_FALL_SAF />
            </Route>

            <Route exact path="/tour-packages/taste-of-uganda">
              <TST_OF_UG />
            </Route>

            <Route exact path="/tour-packages/17-days-intrepid">
              <S17D_INT />
            </Route>
            
            {/* <Route exact path="/blogs">
              <Blog />
            </Route> */}

            <Route exact path="/contact-us">
              <Contact />
            </Route>
            
            <Route path="*">
              <NotFound />
            </Route>

          </Switch>
        </div>

        <Footer />

      </div>
    
    </Router>
  );
}

export default App;
