const DayTen = () => {
    return (
        <section id="day10" className="package-detail tabcontent">
            <h1 className="title">Day 10: Ishasha- Queen Elizabeth National Park</h1>

            <p className="text"><b>Activities for the day: Breakfast, game drive and transfer North to Queen Elizabeth National Park.</b></p>

            <p className="text">After breakfast, you will have a game drive through Ishasha, looking out for the tree climbing lions and other game.</p>

            <p className="text">Later proceed to Queen Elizabeth National park for one night. Queen Elizabeth National Park boasts one of the highest biodiversity ratings of any game park or reserve in the world. Uganda’s second largest National Park, the 1,978 sq km has everything from open savannah to rain forest. Queen Elizabeth has over 100 mammal species, and an astounding 606 bird species, making it a premier safari destination. Arrive in time for lunch and later go for a late afternoon game drive returning to your lodge for the night.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayTen;