const DayEight = () => {
    return (
        <section id="day8" className="package-detail tabcontent">
            <h1 className="title">Day 8: Lake Mburo - Entebbe/ Fly Out</h1>
            <p className="text">Today enjoy an early morning game drive or an optional walking safari in Lake Mburo National Park. Afterwards transfer from Lake Mburo, crossing the equator along the way, to the airport for your flight home.</p>
            
            <p className="text">Return to your lodge and relax.</p>

            <div className="details">
                <h3 className="small-title">Cost inclusions :</h3>
                <ul>
                    <li>All meals and accommodation as per the itinerary</li>
                    <li>All ground transportation in a comfortable 4x4 vehicle</li>
                    <li>Park entrance fees</li>
                    <li>Services of a full time English speaking driver/guide</li>
                    <li>Water while on safari</li>
                    <li>All airport pick-ups and drops</li>
                    <li>All activities as stipulated in the itinerary</li>
                    <li>All government taxes/levies</li>
                </ul>
            </div>

            <div className="details">
                <h3 className="small-title">Excluded are :</h3>
                <ul>
                    <li>All optional activities or forest walks</li>
                    <li>Phone calls and bar bills</li>
                    <li>Personal insurance</li>
                    <li>Laundry</li>
                    <li>Air fare</li>
                    <li>All other services of a personal nature</li>
                    <li>Driver guide’s gratuity</li>
                </ul>
            </div>

        </section>
    );
}
 
export default DayEight;