import { useHistory } from 'react-router-dom';

const NotFound = () => {

    const history = useHistory();

    return (
        <main className="page status-page">
            <section className="wrapper content">
                <div>
                    <h1 className="title">The page you requested does not exist</h1>

                    <button onClick={()=>history.goBack()}>Take me back</button>
                </div>
            </section>
        </main>
    );

}

export default NotFound;