const DayTwelve = () => {
    return (
        <section id="day12" className="package-detail tabcontent">
            <h1 className="title">Day 12: Kibale</h1>

            <p className="text"><b>Activities for the Day: Breakfast, morning crater walk, lunch with the locals if possible and afternoon Chimp tracking</b></p>

            <p className="text">This morning you will go for a crater lakes walk. Formed by ancient volcanic activity, the crater lakes of Uganda are located near Fort Portal, and are a great hiking option. Typical hikes take you through local villages and tea plantations, to the base of several of these incredibly serene and beautiful lakes. With the Rwenzori Mountains in the background, this is by far one of Uganda’s most peaceful and extraordinary sights.</p>
            
            <p className="text">Have lunch with the local people if possible and later go for the afternoon chimp tracking. Seeing habituated chimps squabbling and swinging from the trees is an exhilarating experience. Return to the hotel in the evening for the overnight.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Kibale Guest Cottages</p>
                <p><b>Premium</b> : Papaya Lake Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayTwelve;