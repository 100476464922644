const DayFive = () => {
    return (
        <section id="day5" className="package-detail tabcontent">
            <h1 className="title">Day 5: Ruhengeri - Bwindi southern sector</h1>

            <p className="text"><b>Activities for the day: Breakfast and then transfer to Bwindi.</b></p>

            <p className="text">After breakfast, continue to Bwindi Impenetrable Forest for two nights. Bwindi is home to over half of the world’s mountain gorilla population. Interacting with these gentle giants is one of the most moving experiences that Africa has to offer and should not be missed! The ancient rainforest of Bwindi spans 321 sq km and boasts 90 mammal species, a range of primates, as well as 23 Albertine Rift endemic bird species, including the African Green Broadbill, the Bar Tailed Trogon and many others.</p>

            <p className="text">Relax in the evening and get ready for the following day’s gorilla tracking.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Rushaga gorilla camp</p>
                <p><b>Premium</b> : Ichumbi Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayFive;