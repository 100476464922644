const DaySeven = () => {
    return (
        <section id="day7" className="package-detail tabcontent">
            <h1 className="title">Day 7: Bwindi to Lake Mburo National Park</h1>

            <p className="text">Today, you will transfer to Lake Mburo National Park and have a game drive while entering the park. This wildlife sanctuary offers excellent plains game viewing and is the best place in the country to see eland, zebra, topi and impala. You should also have excellent sightings of giraffe, bushbuck, warthog, vervet and red-tailed monkeys, olive baboon, pigmy mongoose, white tailed mongoose and buffalo. If you are very fortunate you may even see leopard.</p>

            <p className="text">Continue to your lodge. You can simply relax or take part in optional activities.</p>

            <div className="details">
                <h3 className="small-title">Optional Activities:</h3>
                <p className="text">1. Horseback Riding Safaris:  What better way to explore the park than on horseback!  Horseback riding safaris are available for various times and are suitable for all levels of riders including the non-experienced.</p>
                <p className="text">2. Boat Safari: Take a cruise along Lake Mburo which is home to dense populations of hippos and crocodiles.</p>
            </div>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your lodge</p>
                <p><b>Comfort</b> : Rwakobo Rock Lodge</p>
                <p><b>Premium</b> : Mihingo Lodge</p>
            </div>
        </section>
    );
}
 
export default DaySeven;