const DaySeventeen = () => {
    return (
        <section id="day17" className="package-detail tabcontent">
            <h1 className="title">Day 17: Kampala and later transfer to Entebbe</h1>

            <p className="text"><b>Activities of the day: Breakfast, city tour of Kampala and transfer to the airport</b></p>

            <p className="text">Depending on your flight, you can have a city tour and later transfer to the airport to catch your flight back home.</p>

            <div className="details">
                <h3 className="small-title">Cost inclusions :</h3>
                <ul>
                    <li>Gorilla permits in Uganda</li>
                    <li>1 chimpanzee permit</li>
                    <li>All meals and accommodation as per the itinerary</li>
                    <li>All ground transportation in a comfortable 4x4 vehicle</li>
                    <li>Park entrance fees</li>
                    <li>Golden monkey tracking fees</li>
                    <li>Services of a full time English speaking driver/guide</li>
                    <li>Water while on safari</li>
                    <li>All boat trips</li>
                    <li>All game drives</li>
                    <li>All airport pick-ups and drops</li>
                    <li>Bigodi swamp walk or crater lakes walk</li>
                    <li>Village walk in Lake Bunyonyi</li>
                    <li>All activities as stipulated in the itinerary</li>
                    <li>All government taxes/levies</li>
                </ul>
            </div>

            <div className="details">
                <h3 className="small-title">Excluded are :</h3>
                <ul>
                    <li>Optional activities if requested for</li>
                    <li>Phone calls and bar bills</li>
                    <li>Balloon safari</li>
                    <li>Personal insurance</li>
                    <li>Laundry</li>
                    <li>Air fare</li>
                    <li>All other services of a personal nature</li>
                    <li>Driver guide’s gratuity</li>
                    <li>A day room if requested on the last day</li>
                </ul>
            </div>

        </section>
    );
}
 
export default DaySeventeen;