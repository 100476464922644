import Banner from "./partials/Banner";
import Rwanda from "./destinations/Rwanda";
import Uganda from "./destinations/Uganda";

const Destinations = () => {

    return (
        <main id="destinations" className="page">

            <Banner 
                title="Next stop, Adventure!" 
                subtile="Check out some of the best places in the East African region. The best experience of your African tour awaits" 
            />

            <Uganda />

            <Rwanda />

        </main>
    );

}

export default Destinations;