const DayTen = () => {
    return (
        <section id="day10" className="package-detail tabcontent">
            <h1 className="title">Day 10: Bwindi – Rwanda</h1>

            <p className="text">This morning you will have breakfast and then drive to the Uganda- Rwanda boarder and cross to Rwanda.</p>

            <p className="text">Arrive at your lodge where you can simply relax or have an optional village walk. You will need to have a good night’s rest to get ready for the following day’s second gorilla tracking.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Mountain Gorilla View Lodge</p>
                <p><b>Premium</b> : Five Volcanoes Boutique Hotel</p>
            </div>
        </section>
    );
}
 
export default DayTen;