const DayOne = () => {
    return (
        <section id="day1" className="package-detail tabcontent">
            <h1 className="title">Day 1: Arrival and transfer to your hotel</h1>
            <p className="text">On arriving at the airport, you will be warmly welcomed by a Hadfar Safaris representative who will then transfer you to your hotel. Check in and relax under warm blue Ugandan skies.</p>
            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your lodge on FB</p>
                <p><b>Comfort</b> : Lake Victoria View Guest House</p>
                <p><b>Premium</b> : Protea Hotel Entebbe</p>
            </div>
        </section>
    );
}
 
export default DayOne;