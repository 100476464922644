const DayEight = () => {
    return (
        <section id="day8" className="package-detail tabcontent">
            <h1 className="title">Day 8: Bwindi Impenetrable National park– Lake Bunyonyi</h1>
            <p className="text">Wake up to the typical Bwindi murky morning for your Gorilla Tracking. You will set off to the tracking point for briefing and thereafter start off for the trek to see the gorillas. You will have a rare opportunity to observe the everyday interactions of these gentle, mysterious giants. Spending time with the gorillas is an opportunity of a life time, one that should not be missed. Tracking times can vary though, as these are wild animals after all.</p>
            
            <p className="text">In the afternoon, transfer to Lake Bunyonyi, the deepest lake in Africa!</p>

            <p className="text">Check in at your lodge and enjoy the beautiful scenery before you.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Supreme Adventure Park</p>
                <p><b>Premium</b> :  Birdnest</p>
                <p><b>Meal Plan</b> :  Breakfast, Lunch and Dinner</p>
            </div>

        </section>
    );
}
 
export default DayEight;