const DaySix = () => {
    return (
        <section id="day6" className="package-detail tabcontent">
            <h1 className="title">Day 6: Bwindi</h1>

            <p className="text"><b>Activities of the day: Gorilla tracking for the day and possible optional activities in the evening.</b></p>

            <p className="text">This morning you will set off for gorilla trekking. Hiking through the thick, verdant, rainforest to eventually come across the gorillas in their natural habitat, is an amazing and unforgettable experience. Actual trek times can vary; these are wild animals after all. If time permits there are a diverse range of activities which are unique to Bwindi which you can participate in during the afternoon.</p>

            <div className="details">
                <h3 className="small-title">Optional Activities</h3>
                <p><b>Batwa Trail</b> :  During this moving tour, the Batwa demonstrate hunting techniques; gather honey; point out medicinal plants and demonstrate how to make bamboo cups. Guests are then invited to the sacred Ngarama Cave, once home to the Batwa King to be entertained by traditional music and dance.</p>

                <p><b>Village Walk</b> : Overlooking the imposing hillsides of Bwindi Impenetrable forest, with the mist swirling over the summits, this is one of the most dramatic settings for your cultural tour! Among others, meet a traditional healer, who not only treats the sick, but uses his knowledge to conserve and educate about the various medicinal plants.</p>
            </div>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Rushaga gorilla camp</p>
                <p><b>Premium</b> : Ichumbi Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DaySix;