const DayOne = () => {
    return (
        <section id="day1" className="package-detail tabcontent">
            <h1 className="title">Day 1: Arrival – Meet and greet, continue to your hotel</h1>
            <p className="text">On arrival at the airport, you will be warmly welcomed by your representative from Hadfar Safaris who will then transfer you to Entebbe for the overnight. Overnight at your accommodation.</p>
            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Papyrus Guest House</p>
                <p><b>Premium</b> : Lake Victoria Serena Hotel</p>
            </div>
        </section>
    );
}
 
export default DayOne;