const DayFive = () => {
    return (
        <section id="day5" className="package-detail tabcontent">
            <h1 className="title">Day 5: Queen Elizabeth National Park.</h1>

            <p className="text">In morning, you will have a game drive to the Kasenyi area to look for lion prides and other wildlife like the Uganda Kob, before returning to the Lodge for breakfast.</p>

            <p className="text">In the afternoon take a cruise along the Kazinga Channel, home to one of the largest concentrations of hippopotamus, as well as herds of elephants, buffalo, and other wildlife that come to drink from the river. The Kazinga also attracts a tremendous variety of bird species including the African Eagle, Open-Billed Stork, and Pied Kingfishers.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice.</p>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayFive;