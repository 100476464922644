const DayFour = () => {
    return (
        <section id="day4" className="package-detail tabcontent">
            <h1 className="title">Day 4: Queen Elizabeth National Park</h1>
            <p className="text">This morning, you will have a game drive in the Kasenyi area where you will catch a glimpse of big game and who knows, you may even have a rare chance of seeing lions pouncing on herds of unsuspecting kob!</p>

            <p className="text">In the afternoon, take a boat cruise along the Kazinga Channel. The 36km Kazinga Channel connects two Rift Valley lakes: Edward and George. These are named after Queen Victoria’s son and heir, Edward VII and his own successor, George V. Various animals come to the banks of the lakes to drink water. You will be able to see elephant, buffalo, hippos which puff and spray just few meters away from your boat, many birds including Great White and Pink-beaked Pelicans, Great and Long-Tailed Cormorants, Open-Billed Stork, African Jacana, Malachite and Pied Kingfishers among others.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your lodge</p>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayFour;