const DaySix = () => {
    return (
        <section id="day6" className="package-detail tabcontent">
            <h1 className="title">Day 6: Kibale – Queen Elizabeth National Park</h1>

            <p className="text">After breakfast, you will depart Kibale and then head for Queen Elizabeth National Park. It’s little wonder that Queen Elizabeth boasts one of the highest bio diversity ratings of any game park or reserve in the world, - from the open Savannah to Rain Forest, dense Papyrus Swamps and brooding Crater lakes to the vastness of Lake Edward.</p>

            <p className="text">Almost 100 mammal species that include Lions, Hippos, Buffaloes, Leopard, Spotted Hyena, Elephants and the elusive Giant Forest Hog plus remarkable 606 bird species that include the Black Chested Eagle, the Swallow Tailed Bee Eaters, to mention but a few.</p>

            <p className="text">The Primates include Chimps, Black and White Colobus Monkeys, Blue, Black Faced Red Colobus, and Red Tailed monkeys and Olive Baboons.</p>

            <p className="text">Reptiles are the amazing huge Rock-Python, Puff adders, Spitting Cobras, Gabon vipers, Green Mambas, and Turtles etc. All these are regularly sighted around the tourist village on the Mweya Peninsula and all this makes a superb safari territory.</p>

            <p className="text">Arriving in the afternoon, you will have lunch and later go for a boat cruise along the Kazinga Channel. Hippos huff and spray mere feet away from the boat, Buffalos linger in the shallows, a tremendous variety of birds grace the grasses on the shores. Great White and Pink-Backed Pelicans, Great and Long-Tailed Cormorants, Open-Billed Stork, African Jacana, Malachite and Pied Kingfishers are commonly seen.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
            </div>
        </section>
    );
}
 
export default DaySix;