import { Link } from 'react-router-dom';

const Banner = () => {

    return (
        <section className="main-banner banner">
            <div className="overlay">
                <div className="wrapper">
                    <h1 className="title">Hadfar Safaris</h1>
                    <p className="subtitle">From Uganda to Rwanda, expect a unique experience while touring some of the best places in the east african region.</p>
                    <p className="small-subtitle">"Your safari gets better with every step you take."</p>
                    <Link to="/about" className="button" >Explore</Link>
                </div>
            </div>
        </section>
    );

}

export default Banner;