const DaySeven = () => {
    return (
        <section id="day8" className="package-detail tabcontent">
            <h1 className="title">Day 8: Ishasha - Bwindi Impenetrable Forest National Park</h1>

            <p className="text">After breakfast, you will depart Ishasha for Bwindi passing via the Kigezi region, a region with beautiful scenery of hills and valleys and terrace farming in between. Kigezi is popularly known as ‘the Switzerland of Africa, because of its hilly nature and coldness. Arrive at your lodge in the late afternoon.</p>

            <p className="text">Relax after lunch. You will need to have a good night’s rest to get ready for the following day’s gorilla tracking.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice / Depends on sector where you track gorillas</p>
                <p><b>Duration</b> : Approx. 5 hours’ drive</p>
                <p><b>Comfort</b> : Buhoma haven Lodge/ Rushaga Gorilla Camp</p>
                <p><b>Premium</b> : Mahogany Springs/ Gorilla Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DaySeven;