import Banner from './home/Banner';
import Destinations from './home/Destinations';
import Tours from './home/Tours';
import TourPackages from './home/TourPackages';
import Contact from './home/Contact';

const Home = () => {

    return (
        <main id="home" className="page">
            
            <Banner />

            <Destinations />

            <Tours />

            <TourPackages />

            <Contact />

        </main>
    );

}

export default Home;