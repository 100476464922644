const DayThirteen = () => {
    return (
        <section id="day13" className="package-detail tabcontent">
            <h1 className="title">Day 13: Kibale - Murchison falls National Park</h1>

            <p className="text">After breakfast, you will depart Kibale for Murchison Falls National Park, stopping en route for lunch arriving in the late afternoon.</p>
            
            <p className="text">In the afternoon, you will have a game drive as you enter the park and thereafter visit the incredible Top of the falls and later proceed to your lodge in the evening for the overnight.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                <p><b>Premium</b> : Paraa Safari Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayThirteen;