const DaySeven = () => {
    return (
        <section id="day7" className="package-detail tabcontent">
            <h1 className="title">Day 7: Bwindi – Kigali</h1>

            <p className="text">After breakfast, you will proceed to Kigali and continue to your lodge to relax. Enjoy the warm blue African skies.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Hotel des Mille Collines</p>
                <p><b>Premium</b> : Serena Kigali</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DaySeven;