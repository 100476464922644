const Banner = ({title, subtile}) => {

    return (
        <section className="child-banner banner">
            <div className="overlay">
                <div className="wrapper">
                    <h1 className="title">{title}</h1>
                    <p className="subtitle">{subtile}</p>
                </div>
            </div>
        </section>
    );
}
 
export default Banner;