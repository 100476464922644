import { Link } from 'react-router-dom';

const Destinations = () => {

    return (
        <section className="section destinations">
            <div className="wrapper">
                <div className="intro">
                    <h1 className="medium-title title">Destinations</h1>
                    <p className="text">From Uganda to Rwanda, expect a unique experience while touring some of the best places in the east african region.</p>
                </div>
                <div className="single-destination uganda">
                    <div className="overlay">
                        <div className="container">
                            <div>
                                <h2 className="title">Uganda</h2>
                                <p className="tag">The Pearl Of Africa</p>
                            </div>
                            <div className="fast-facts">
                                <p className="text"><b>Capital:</b> Kampala</p>
                                <p className="text"><b>Population:</b> 35.6 million</p>
                                <p className="text"><b>Currency:</b> Ugandan Shilling</p>
                                <Link className="button" to="./destinations/uganda">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="single-destination rwanda">
                    <div className="overlay">
                        <div className="container">
                            <div>
                                <h2 className="title">Rwanda</h2>
                                <p className="tag">Land Of A Thousand Hills</p>
                            </div>
                            <div className="fast-facts">
                                <p className="text"><b>Capital:</b> Kigali</p>
                                <p className="text"><b>Population:</b> 11.2 million</p>
                                <p className="text"><b>Currency:</b> Rwandan Franc</p>
                                <Link className="button" to="./destinations/rwanda">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Destinations;