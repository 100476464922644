import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./UG6D_BW_QN/DayOne";
import DayTwo from "./UG6D_BW_QN/DayTwo";
import DayThree from "./UG6D_BW_QN/DayThree";
import DayFour from "./UG6D_BW_QN/DayFour";
import DayFive from "./UG6D_BW_QN/DayFive";
import DaySix from "./UG6D_BW_QN/DaySix";

const UG6D_BW_QN = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'}
    ];

    return (

        <main id="ug6d-bw-qn" className="page single-package">

            <BannerArea 
                title="Uganda 6 Days 5 Nights Bwindi and Queen" 
                location="Bwindi and Queen Elizabeth National Parks" 
                days="6" 
                nights="5" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <aside className="side-menu">

                    <Quote package_name="Uganda 6 days 5 nights Bwindi and Queen"/>
                    
                </aside>

            </section>

        </main>
    );

}

export default UG6D_BW_QN;