const DayFive = () => {
    return (
        <section id="day5" className="package-detail tabcontent">
            <h1 className="title">Day 5: Kibale Forest</h1>
            <p className="text">This morning, you will go for chimp tracking and be part of the team that goes to study the behaviour of these primates in order to make them get used to human beings. Spend the whole morning in the forest returning to the lodge in time for lunch.</p>

            <p className="text">Later in the evening, you will have the opportunity of a guided walk through Bigodi Swamp. Bigodi Wetland Sanctuary is managed by the Kibale Association for Rural and Environmental organisation formed in 1992 with the aim of achieving social and economic development for local communities. This swamp is a home to approximately 137 bird species and it is also rich in vegetation, such as wild palms, polita figs and the dominant papyrus.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Dinner and overnight at your accommodation</p>
                <p><b>Comfort</b> : Chimpanzee Forest Guest House</p>
                <p><b>Premium</b> : Primate Lodge</p>
            </div>
        </section>
    );
}
 
export default DayFive;