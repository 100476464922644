import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

const RW3D_GR_EX = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'}
    ];

    return (

        <main id="rw3d-gr-ex" className="page single-package">

            <BannerArea 
                title="Rwanda, Uganda 3 days Gorilla Express itinerary" 
                location="Bwindi Impenetrable National Park" 
                days="3" 
                nights="2" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <section id="day1" className="package-detail tabcontent">
                    <h1 className="title">Day 1: Kigali - Bwindi Impenetrable National Park</h1>
                    <p className="text">After breakfast, your guide will pick you from Kigali and drive towards the Kigezi area to the famous Bwindi Impenetrable national Park. Bwindi Impenetrable National Park, also called “The Place of Darkness” due to its dense treetops lies on the eastern edge of the Albertine Rift Valley covering approximately 321 sq. km. Bwindi is home to almost half of the world’s mountain gorilla population. It is also a biodiversity hotspot providing shelter to a further 120 mammals, including several primate species such as baboons and chimpanzees, as well as elephants and antelopes. There are around 350 species of birds hosted in this forest, including 23 Albertine Rift endemics. You will have the chance to spot hornbills and turacos, 220 butterfly species, 27 frog species as well as chameleons, geckos and many other endangered species. Bwindi also boasts over 1,000 different flowering plants, ferns & trees</p>
                    <p className="text">Check into your lodge and enjoy your remote African surroundings</p>
                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p><b>Comfort</b> : Rushaga Gorilla Camp</p>
                        <p><b>Premium</b> : Ichumbi Lodge</p>
                        <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
                    </div>
                </section>

                <section id="day2" className="package-detail tabcontent">
                    <h1 className="title">Day 2: Bwindi Impenetrable National Park</h1>
                    <p className="text">Wake up to the typical Bwindi murky morning for your Gorilla Tracking. You will set off to the tracking point for briefing and thereafter start off for the trek to see the gorillas. You will have a rare opportunity to observe the everyday interactions of these gentle, mysterious giants. Spending time with the gorillas is an opportunity of a life time, one that should not be missed. Tracking times can vary though, as these are wild animals after all.</p>
                    <p className="text">Return to your lodge and relax or you can take part in optional activities.</p>
                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p><b>Comfort</b> : Rushaga Gorilla Camp</p>
                        <p><b>Premium</b> : Ichumbi Lodge</p>
                        <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
                    </div>
                </section>

                <section id="day3" className="package-detail tabcontent">
                    <h1 className="title">Day 3: Bwindi – Kigali</h1>
                    <p className="text">After breakfast, drive back to Kigali. You can have a city tour by visiting the caplaki crafts center or genocide museum before continuing to the airport to catch your flight back home.</p>

                    <div className="details">
                        <h3 className="small-title">Cost inclusions :</h3>
                        <ul>
                            <li>All meals and accommodation as per the itinerary</li>
                            <li>All ground transportation in a comfortable 4x4 vehicle</li>
                            <li>Park entrance fees</li>
                            <li>Services of a full time English speaking driver/guide</li>
                            <li>Water while on safari</li>
                            <li>All airport pick-ups and drops</li>
                            <li>All activities as stipulated in the itinerary</li>
                            <li>All government taxes/levies</li>
                        </ul>
                    </div>

                    <div className="details">
                        <h3 className="small-title">Excluded are :</h3>
                        <ul>
                            <li>Gorilla permit, optional activities or forest walks</li>
                            <li>Phone calls and bar bills</li>
                            <li>Personal insurance</li>
                            <li>Laundry</li>
                            <li>Air fare</li>
                            <li>All other services of a personal nature</li>
                            <li>Driver guide’s gratuity</li>
                        </ul>
                    </div>

                </section>

                <aside className="side-menu">

                    <Quote package_name="Rwanda Uganda 3 days Gorilla Express"/>

                </aside>

            </section>

        </main>
    );

}

export default RW3D_GR_EX;