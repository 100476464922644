// const BannerArea = (title, location, days, nights) => {
const BannerArea = ({title, location, days, nights}) => {

    return (
        <div className="banner-area">
            <div className="overlay">
                <div className="wrapper info">
                    <h1 className="title">{title}</h1>
                    <p className="text"><span className="ldng">Location :</span> {location}</p>
                    <div className="days">
                        <p className="text"><span className="ldng">Days :</span> {days}</p>
                        <p className="text"><span className="ldng">Nights :</span> {nights}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default BannerArea;