const DayEleven = () => {
    return (
        <section id="day11" className="package-detail tabcontent">
            <h1 className="title">Day 11: Gorilla tracking in Rwanda</h1>

            <p className="text">After breakfast it’s just a short 10minute drive to the park headquarters at Kinigi. Registration is a simple process and it is here where the park staff will determine which group of gorillas you will visit. The park staff will discuss your fitness level and will advise you of the trekking times and conditions to reach the various groups, based upon their location in the park the previous evening.</p>

            <p className="text">Once the trekkers are put into groups, each group’s lead guide will advise the group of the trekking procedures as well as the “do’s and don’ts” while in the presence of gorillas. After the briefing, trekkers return to their respective vehicles for the drive up the mountain to the starting point. Once at the starting point all trekkers are offered a porter to carry their backpack and any other gear. The guides stop frequently to allow trekkers a chance to rest and take photos along the way. Return in the afternoon. There is an option for the Ibyiwatu Cultural group performance where you will get entertained by the Rwandase and get to know about their culture in music, dance and drama.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Mountain Gorilla View Lodge</p>
                <p><b>Premium</b> : Five Volcanoes Boutique Hotel</p>
            </div>
        </section>
    );
}
 
export default DayEleven;