const DayTwo = () => {
    return (
        <section id="day2" className="package-detail tabcontent">
            <h1 className="title">Day 2: Entebbe - Murchison Falls National Park</h1>
            <p className="text">Today, you will transfer to Murchison Falls national park, passing via Ziwa rhino sanctuary for rhino trekking.</p>

            <p className="text">The objective of creating the sanctuary was to save Uganda’s rhinos from complete extinction. Sight of black and white rhinos here is certainly going to delight the wildlife enthusiasts. This is a safari where you may remain just a few feet away from the rhinos. Later, proceed to Murchison falls national Park and visit the top of the falls before continuing to your lodge. Relax as you wait to explore Uganda’s largest national park the next day.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                <p><b>Premium</b> : Paraa Safari Lodge</p>
            </div>
        </section>
    );
}
 
export default DayTwo;