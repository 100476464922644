const DayThree = () => {
    return (
        <section id="day3" className="package-detail tabcontent">
            <h1 className="title">Day 3: Kigali – Ruhengeri</h1>
            <p className="text"><b>Activities of the day: Visit Gisozi Genocide, lunch in Kigali and transfer to Ruhengeri.</b></p>

            <p className="text">Today, you will visit Gisozi Genocide Memorial before departing Kigali. Lunch in Kigali at a local restaurant and afterwards proceed to Ruhengeri. The three-hour drive through the rolling hills of Rwanda takes you to the base of the magnificent volcanoes of the Virunga mountain range. Arrive at the lodge in the evening and relax and get ready for the following day’s Golden monkey tracking.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Mountain Gorilla View Lodge</p>
                <p><b>Premium</b> : Five Volcanoes Boutique Hotel</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayThree;