const DayEleven = () => {
    return (
        <section id="day11" className="package-detail tabcontent">
            <h1 className="title">Day 11: Lake Mburo – Entebbe</h1>

            <p className="text">After breakfast, have game drive or an optional guided forest walk and then proceed to Entebbe. Have a stop to look at the Royal Drum makers and the Equator - where you will be able to stand and be on both hemispheres simultaneously.</p>

            <p className="text">Later continue to Entebbe and check in at your hotel.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Airport View Hotel</p>
                <p><b>Premium</b> : Hotel No. 5</p>
                <p><b>Meal Plan</b> : Bed and Breakfast</p>
            </div>
        </section>
    );
}
 
export default DayEleven;