import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./BT_SUN/DayOne";
import DayTwo from "./BT_SUN/DayTwo";
import DayThree from "./BT_SUN/DayThree";
import DayFour from "./BT_SUN/DayFour";
import DayFive from "./BT_SUN/DayFive";
import DaySix from "./BT_SUN/DaySix";
import DaySeven from "./BT_SUN/DaySeven";
import DayEight from "./BT_SUN/DayEight";

const BT_SUN = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'},
        {id: 7, dayElement: 'day7', dayBtn: 'day7btn', text: 'day 7'},
        {id: 8, dayElement: 'day8', dayBtn: 'day8btn', text: 'day 8'}
    ];

    return (

        <main id="bt-sun" className="page single-package">

            <BannerArea 
                title="Beautiful Sunshine" 
                location="Rwanda and Uganda" 
                days="8" 
                nights="7" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <DaySeven />

                <DayEight />

                <aside className="side-menu">
                    
                    <Quote package_name="Beautiful Sunshine"/>

                </aside>

            </section>

        </main>
    );

}

export default BT_SUN;