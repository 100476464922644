import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./UG10D_TOUR/DayOne";
import DayTwo from "./UG10D_TOUR/DayTwo";
import DayThree from "./UG10D_TOUR/DayThree";
import DayFour from "./UG10D_TOUR/DayFour";
import DayFive from "./UG10D_TOUR/DayFive";
import DaySix from "./UG10D_TOUR/DaySix";
import DaySeven from "./UG10D_TOUR/DaySeven";
import DayEight from "./UG10D_TOUR/DayEight";
import DayNine from "./UG10D_TOUR/DayNine";
import DayTen from "./UG10D_TOUR/DayTen";

const UG10D_TOUR = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'},
        {id: 7, dayElement: 'day7', dayBtn: 'day7btn', text: 'day 7'},
        {id: 8, dayElement: 'day8', dayBtn: 'day8btn', text: 'day 8'},
        {id: 9, dayElement: 'day9', dayBtn: 'day9btn', text: 'day 9'},
        {id: 10, dayElement: 'day10', dayBtn: 'day10btn', text: 'day 10'}
    ];

    return (

        <main id="ug10d-tour" className="page single-package">

            <BannerArea 
                title="Uganda 10 Days Tour" 
                location="Variety in Uganda" 
                days="10" 
                nights="9" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <DaySeven />

                <DayEight />

                <DayNine />

                <DayTen />

                <aside className="side-menu">

                    <Quote package_name="Uganda 10 days tour"/>
                    
                </aside>

            </section>

        </main>
    );

}

export default UG10D_TOUR;