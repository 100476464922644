const TourPackages = () => {

    return (
        <section className="section tours-list">
            <div className="wrapper section-intro">
                <h1 className="title">Tour Packages</h1>
                <p className="text">We offer some of the best tour packages in the industry. Book with us
                and you will get the best packages with value of your money.</p>
            </div>
            <div className="wrapper information">
                <div className="package-list">
                    <div className="img one"></div>
                    <div>
                        <h2 className="small-title">African Safari Tour</h2>
                        <p>If your idea of a perfect vacation includes being exposed to other cultures and interacting with local people, then look no further than this exclusive tour.</p>
                    </div>
                </div>
                <div className="package-list">
                    <div className="img two"></div>
                    <div>
                        <h2 className="small-title">Cultural Tour</h2>
                        <p>When you visit a new destination, do you enjoy touring around with a professional guide? If so, Hadfar Safaris is ready to serve your every need.</p>
                    </div>
                </div>
                <div className="package-list">
                    <div className="img three"></div>
                    <div>
                        <h2 className="small-title">City Tour</h2>
                        <p>As our guides give you a tour around the city, you get a chance to interract with local people, and get to hear their perspectives on the wonders of our world</p>
                    </div>
                </div>
                <div className="package-list">
                    <div className="img four"></div>
                    <div>
                        <h2 className="small-title">Spetacular Nature Treats</h2>
                        <p>The great lakes region has breathtaking sceneries to cheer you up as you count the value for every single coin. Of course with our guidance.</p>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default TourPackages;