const Quote = ({package_name}) => {

    return (
        <form action="/quote/quote.php" method="POST" className="quote">
            <h3 className="small-title">Request Quote</h3>
            <p>{package_name}</p>
            
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Your Name" required />

            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" placeholder="Your Email" required />

            <input type="hidden" id="packagename" name="packagename" value={"Requesting quote for "+package_name} />
            <button type="submit" name="sendquote">Send Request</button>
        </form>
    );
}
 
export default Quote;