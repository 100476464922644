import { Link } from 'react-router-dom';

const Tours = () => {

    const packages_route = "/tour-packages";

    return (
        <section className="section home-page-tours">
            <div className="wrapper section-intro">
                <h1 className="title">Our Tours</h1>
                <p className="text">Check out some of the best places in the East African region. The best experience of your African tour awaits.</p>
            </div>
            <div className="tour-container wrapper">
                <div className="single-tour" id="kibale">
                    <Link to={packages_route+"/uganda-8-days-great-lakes-and-the-chimpazees"}>
                        <div className="overlay">
                            <p>Kibale Forest National Park</p>
                        </div>
                    </Link>
                </div>
                <div className="single-tour" id="queen">
                    <Link to={packages_route+"/uganda-6-days-5-nights-bwindi-and-queen"}>
                        <div className="overlay">
                            <p>Queen Elizabeth National Park</p>
                        </div>
                    </Link>
                </div>
                <div className="single-tour" id="bwindi">
                    <Link to={packages_route+"/rwanda-uganda-3-days-gorilla-express"}>
                        <div className="overlay">
                            <p>Bwindi Impenetratable Forest</p>
                        </div>
                    </Link>
                </div>
                <div className="single-tour" id="mburo">
                    <Link to={packages_route+"/beautiful-sunshine"}>
                        <div className="overlay">
                            <p>Lake Mburo National Park</p>
                        </div>
                    </Link>
                </div>
                <div className="single-tour" id="volcanoes">
                    <Link to={packages_route+"/rwanda-3-days-gorilla-tracking"}>
                        <div className="overlay">
                            <p>Volocanoes National Park</p>
                        </div>
                    </Link>
                </div>
                <div className="single-tour" id="murchison">
                    <Link to={packages_route+"/murchison-falls-safari"}>
                        <div className="overlay">
                            <p>Murchison Falls National Park</p>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    );

}

export default Tours;