const DayTwelve = () => {
    return (
        <section id="day12" className="package-detail tabcontent">
            <h1 className="title">Day 12: Ruhengeri - Kigali</h1>

            <p className="text">After breakfast, you can go for the optional golden monkey tracking and later continue to Kigali. Spend the evening relaxing at your hotel.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Flame Tree Village Hotel</p>
                <p><b>Premium</b> : Hotel des Mille Collines</p>
            </div>
        </section>
    );
}
 
export default DayTwelve;