const DayNine = () => {
    return (
        <section id="day9" className="package-detail tabcontent">
            <h1 className="title">Day 9: Lake Bunyonyi – Ishasha</h1>

            <p className="text"><b>Activities for the day: Breakfast and transfer to Ishasha.</b></p>

            <p className="text">Today you proceed to Ishasha, home to the famed tree climbing lions. The lions are located in the Southern sector of Queen Elizabeth National Park, and are best seen in early hours of the morning..</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Ishasha Jungle Lodge</p>
                <p><b>Premium</b> : Ishasha Wilderness Camp</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayNine;