const DayTwo = () => {
    return (
        <section id="day2" className="package-detail tabcontent">
            <h1 className="title">Day 2: Kigali</h1>

            <p className="text">Today, go for a city tour and explore Kigali city by visiting the Genocide Museum and the Caplaki Crafts Center later return to the lodge to relax.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Hotel des Mille collines</p>
                <p><b>Premium</b> : Serena Kigali</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayTwo;