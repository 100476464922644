import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

const MCSH_FALL_SAF = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'}
    ];

    return (

        <main id="mcsh_fall_saf" className="page single-package">

            <BannerArea 
                title="Murchison Falls Safari" 
                location="Murchison Falls National Park" 
                days="4" 
                nights="3" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <section id="day1" className="package-detail tabcontent">
                    <h1 className="title">Day 1: Arrival and transfer to your hotel</h1>

                    <p className="text">A Hadfar Safaris representative, smart and charming will warmly welcome you upon arrival at the airport. He/she will brief you about your safari and then transfer you to your hotel.</p>

                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p className="text">Overnight at your accommodation of choice</p>
                        <p><b>Comfort</b> : Papyrus guest house</p>
                        <p><b>Premium</b> : Hotel No. 5</p>
                        <p><b>Meal Plan</b> : BB</p>
                    </div>
                </section>

                <section id="day2" className="package-detail tabcontent">
                    <h1 className="title">Day 2: Transfer to Murchison Falls National Park passing via Ziwa Rhino Sanctuary</h1>
                    <p className="text">After breakfast, you will transfer to Murchison Falls National park passing Via Ziwa Rhino Sanctuary. The objective of creating the sanctuary was to save Uganda’s rhinos from complete extinction. Sight of black and white rhinos here is certainly going to delight the wildlife enthusiasts. Have a hot lunch in Ziwa and later continue with your journey to Murchison. Stop by the top of the falls and enjoy this enchanting view. The Nile squeezes through a 7 metre gap plunging 43 meters deep at Murchison falls on its way to Lake Albert.</p>

                    <p className="text">Later check in your lodge where you will spend the night.</p>

                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p className="text">Overnight at your accommodation of choice</p>
                        <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                        <p><b>Premium</b> : Paraa Safari Lodge</p>
                        <p><b>Meal Plan</b> : FB</p>
                    </div>
                </section>

                <section id="day3" className="package-detail tabcontent">
                    <h1 className="title">Day 3: Game drive and boat cruise</h1>

                    <p className="text">This morning, you will have a game drive on the Northern Side of Murchison Falls National Park. The 3,893 sq km park is Uganda’s largest and home to a range of animals, and you will have a chance of viewing some of the Park’s residents including lions, giraffes, hartebeest, buffalo, and elephants. For the birders amongst you, these areas support the secretary bird and the black chested snake eagle, to name a few.</p>

                    <p className="text">In the afternoon, go for a boat trip to the base of Murchison Falls. The banks of the Nile are densely populated with crocodiles, hippopotamus, and a range of other wildlife that come to the river to drink.</p>

                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p className="text">Overnight at your accommodation of choice</p>
                        <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                        <p><b>Premium</b> : Paraa Safari Lodge</p>
                        <p><b>Meal Plan</b> : FB</p>
                    </div>
                </section>

                <section id="day4" className="package-detail tabcontent">
                    <h1 className="title">Day 4: Murchison Fall National Park - Entebbe</h1>

                    <p className="text">Today you will bid this magnificent park farewell and head back to Entebbe to catch your flight home.</p>

                    <div className="details">
                        <h3 className="small-title">Cost inclusions :</h3>
                        <ul>
                            <li>All meals and accommodation as per the itinerary</li>
                            <li>All ground transportation in a comfortable 4x4 vehicle</li>
                            <li>Park entrance fees</li>
                            <li>Services of a full time English speaking driver/guide</li>
                            <li>Water while on safari</li>
                            <li>All airport pick-ups and drops</li>
                            <li>All activities as stipulated in the itinerary</li>
                            <li>All government taxes/levies</li>
                        </ul>
                    </div>

                    <div className="details">
                        <h3 className="small-title">Cost inclusions :</h3>
                        <ul>
                            <li>Airport transfers</li>
                            <li>Transport in 4x4 vehicles /Land cruiser</li>
                            <li>Fuel & all other costs for the car</li>
                            <li>English speaking Ugandan or Rwandan driver/ guide</li>
                            <li>Accommodation and meals as stated above</li>
                            <li>Drinking water in the vehicle and at the lodges</li>
                        </ul>
                    </div>

                    <div className="details">
                        <h3 className="small-title">Excluded are :</h3>
                        <ul>
                            <li>All international flights</li>
                            <li>Cost for Visa</li>
                            <li>Travel and accident insurance</li>
                            <li>Driver/ guide’s gratuities</li>
                            <li>All services of a personal Nature</li>
                        </ul>
                    </div>

                </section>

                <aside className="side-menu">

                    <Quote package_name="Murchison Falls Safari"/>

                </aside>

            </section>

        </main>
    );

}

export default MCSH_FALL_SAF;