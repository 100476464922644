const DayEight = () => {
    return (
        <section id="day8" className="package-detail tabcontent">
            <h1 className="title">Day 8: Lake Bunyonyi</h1>

            <p className="text"><b>Activities of the day: Breakfast, village walk and boat cruise.</b></p>

            <p className="text">You will have breakfast and then go for the village walk exploring the rich culture of the land and in the afternoon go for a boat trip to visit the various Islands on this lake.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Arcadia Cottages</p>
                <p><b>Premium</b> : Birdnest</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>

        </section>
    );
}
 
export default DayEight;