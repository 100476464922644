const DayOne = () => {
    return (
        <section id="day1" className="package-detail tabcontent">
            <h1 className="title">Day 1: Arrival in Kigali</h1>

            <p className="text"><b>Activities of the day: Arrival and transfer to the hotel.</b></p>

            <p className="text">A Hadfar Safaris representative, smart and charming will warmly welcome you upon arrival at the airport. He/she will brief you about your safari and then transfer you to your hotel.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Hotel des Mille collines</p>
                <p><b>Premium</b> : Serena Kigali</p>
                <p><b>Meal Plan</b> : BB</p>
            </div>
        </section>
    );
}
 
export default DayOne;