const DayTwo = () => {
    return (
        <section id="day2" className="package-detail tabcontent">
            <h1 className="title">Day 2: Entebbe - Lake Mburo National Park</h1>
            <p className="text">Depart after breakfast and drive to Lake Mburo National Park. Although it is the smallest park at only 370km2, this enchanting park is noted for its beauty and incredible lush acacia forest. It is also home to 350 bird species as well as zebra, impala, eland, buffalo, oribi, Defassa waterbuck, leopard, hippo, hyena, topi and reedbuck. The park is now largely covered by woodland where there used to be open savannah and In the western part of the park, the savanna is interspersed with rocky ridges and forested gorges while patches of papyrus swamp and narrow bands of lush riparian woodland line many lakes. Have a game drive while you enter the park and later in the evening.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Rwakobo Rock Lodge</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayTwo;