import Banner from "./partials/Banner";

const TourPackages = () => {

    return (
        <main id="about" className="page">

            <Banner 
                title="We believe it's all about you" 
                subtile="Our customers are our priority. We are ready to serve your every need. Let us give you a great experience with value for your money" 
            />

            <section className="section">
                <div className="wrapper about-info">
                    <div className="about-item left">
                        <h3>Who We Are</h3>
                        <p>Hadfar Safaris is a tour operator specializing in guided tailored tours across Uganda and Rwanda's iconic regions. We are a team of professionals pasionate about adventure, ready to take you on your next trip.</p>
                    </div>
                    <div className="right">
                        <div className="about-item">
                            <h3>What We Do</h3>
                            <p>We offer individualized tailored tours and we are here to plan and make your next dream trip happen.</p>
                        </div>
                        <div className="about-item">
                            <h3>Our Mission</h3>
                            <p>To provide high quality services to clients, ensuring maximum satisfaction and value for money while promoting tourism in a socially responsible way.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section about-know">
                <div className="wrapper section-intro">
                    <h2 className="title">Why choose to travel with us</h2>
                    {/* <p className="text">We believe that the key to our business is our clients and their trust, that is why we never compromise when it comes to quality</p> */}
                </div>

                <div className="wrapper packages">

                    <div className="package-list">
                        <h3>Our Activities</h3>
                        <p>Activities range from trekking, tracking, hiking, boat cruises, game drives, nature walks and excursions; featuring a combination of physical activities, interaction with nature and cultural exchange. We guarantee that you will experience unique authentic places, test the region's best culinary dishes, interact with local cultures and marvel at the beauty of the regions.</p>
                    </div>
                    <div className="package-list">
                        <h3>We Cater To Your Interests</h3>
                        <p>We offer a variety of trips to cater to a wide rage of interests and we shall go out of our way to create an itinerary that suits your needs. Individual travellers can create their own customized tours. Our ultimate goal is for you to get an exceptional experience, one where you will tell your own unique story.</p>
                    </div>
                    <div className="package-list">
                        <h3>Accomodation</h3>
                        <p>We asure you neat and clean restaurants, lodges and hotels. We choose hotels with atmost care depending on what you need but also whether they are environmentally and socailly friendly. But mostly, our guests appreciate a warm and welcoming atmosphere in spetacular locations.</p>
                    </div>
                    <div className="package-list">
                        <h3>Value For Your Money</h3>
                        <p>We guarantee value for your money. We believe in providing the best possible service that is worth your money. We strive for Excellency and especially for you to have a memorable trip. This includes getting the very best service for your money.</p>
                    </div>
                    <div className="package-list">
                        <h3>Vehicles</h3>
                        {/* <p>We value minimizing our impact on the environment and therefore keep the size of our travelling groups small. Our adventure trips are limited to a maximum of only 12 participants per group. The trip feel more intimate this way and all through the trip, you will feel like you are travelling with friends. You will be able to get the most out of your trip with these small groups.</p> */}
                        <p>We use 4 x 4 landcruisers that are suitable for the rough terrain typical to an African jungle safari.</p>
                    </div>
                    <div className="package-list">
                        <h3>Ethics</h3>
                        <p>We follow high ethical standards. We aim to increase our guests' awareness of Uganda and Rwanda's pristine surroundings and the importance of conservation. Therefore we often go off the beaten track and always respect the unique fauna and flora of the region.</p>
                    </div>
                    <div className="package-list">
                        <h3>Our Guides</h3>
                        <p>All our guides carry our values of transparency, Integrity, professionalism and excellence. We assure you some of the best guide in the region. Our tour guides/drivers are born and raised in local regions and are well versed with all the activities in the destination. They are well trained and carry certificates from the National Guides Association of Uganda and Rwanda.</p>
                    </div>
                </div>
            </section>
            
        </main>
    );

}

export default TourPackages;