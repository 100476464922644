const Uganda = () => {
    return (

        <section id="uganda" className="wrapper child-page country">

            <div className="overview side-page">

                <h1 className="medium-title">Uganda</h1>
                <p className="subtitle">The Pearl Of Africa</p>

                <div className="country-image">
                    <div className="c-div">
                        <div className="info">
                            <h3 className="title">Kampala</h3>
                            <p className="text">Capital City of Uganda</p>
                        </div>
                    </div>
                    <div className="c-div">
                        <div className="info">
                            <h3 className="title">Crested Crane</h3>
                            <p className="text">Uganda's National Emblem</p>
                        </div>
                    </div>
                    <div className="c-div">
                        <div className="info">
                            <h3 className="title">Shoebill Stork</h3>
                            <p className="text">Only found in Uganda</p>
                        </div>
                    </div>
                </div>

                <h3 className="title">Overview</h3>

                <p className="text">Uganda is located in east Africa, west of Kenya, south of South Sudan, east of the Democratic Republic of Congo, and north of Rwanda and Tanzania. It is landlocked, having no access to the sea.</p>

                <p className="text">The climate is tropical and generally rainy with two dry seasons (December to February and June to August). It is semi-arid in the northeast.</p>
                
                <p className="text">It is little wonder that Winston Churchill, the British Prime Minister, named Uganda "the pearl of Africa". Uganda has a lot of nature and physical attractions to offer!  From the shores of Lake Victoria to the source of the Nile, along Mount Elgon through the Karamojong lands, to the Kidepo Valley in the extreme North on the Kenyan and Sudanese borders, through the Acholi villages to the Murchison Falls you will not only be introduced to most of Uganda's diversified ethnic groups, but also to its abundant wildlife: the chimpanzees of the Kibale forest, the big game and varied fauna of Queen Elizabeth National park, Bwindi's rare mountain gorillas, the acacia savannah of the Lake Mburo National park and the elusive shoebill stork of the Mabamba wetlands.</p>

                <p className="text">Embark on this very delightful and inspiring journey, traverse the wild and lonely lands and live to tell stories. Marvel at the beauty of Uganda’s nature and hospitality of her people!</p>

            </div>

            <aside className="side-menu facts">

                <div className="details-div">
                    <h3 className="title">Fast Facts</h3>
                    <p><b>Capital</b>: Kampala</p>
                    <p><b>Population</b>: 35.6 million </p>
                    <p><b>Size</b>: 241,038 sq km (93,072 sq miles) </p>

                    <ul className="list">
                        <p><b>Major Languages</b>:</p>
                        <li>English (official)</li>
                        <li>Swahili (official)</li>
                        <li>Luganda</li>
                        <li>various Bantu and Nilotic languages</li>
                    </ul>

                    <ul className="list">
                        <p><b>Major Religions:</b></p>
                        <li>Christianity</li>
                        <li>Islam</li>
                    </ul>

                    <p><b>Currency</b>: Ugandan Shilling</p>
                </div>

                <div className="details-div">
                    <h3 className="title">Safari Highlights</h3>
                    <p>Gorilla tracking</p>
                    <p>Game drives</p>
                    <p>Chimp tracking</p>
                    <p>Golden Monkey tracking</p>
                    <p>Cultural experience</p>
                    <p>Boat cruise</p>
                    <p>Market visits</p>
                    <p>City Tour</p>
                </div>

            </aside>

        </section>

    );
}
 
export default Uganda;