const DayThree = () => {
    return (
        <section id="day3" className="package-detail tabcontent">
            <h1 className="title">Day 3: Kibale Forest National Park - Queen Elizabeth National Park</h1>
            <p className="text">This morning enjoy the amazing experience of Chimp Tracking, as you walk through the spectacular Kibale Rainforest with a guide, searching in the high rainforest canopy for these intelligent primates that are our closest relatives.</p>

            <p className="text">Tracking a noisy chimp family is a thrilling experience and is best in the morning, when they come down to the forest floor to forage. The chimps are very mobile, so viewing can be quite unpredictable, but the walk itself is enchanting.</p>

            <p className="text">The birdlife is also outstanding, although the dense forest canopy does mean patience is needed. You can hope to see the huge crowned eagle, the black-and-white flycatcher, African grey parrot, black-necked and yellow-mantled weavers, the beautiful blue turaco and maybe the secretive black bee-eater.</p>
            
            <p className="text">In the afternoon, transfer to Queen Elizabeth National Park and head to your lodge where you will spend the night.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p>Overnight at your lodge</p>
                <p><b>Activities</b> : Chimpanzee tracking and later transfer to Queen Elizabeth National Park.</p>
                <p><b>Comfort</b> :  Kasenyi Safari Camp</p>
                <p><b>Premium</b> :  Katara Lodge</p>
            </div>
        </section>
    );
}
 
export default DayThree;