const DayTwo = () => {
    return (
        <section id="day2" className="package-detail tabcontent">
            <h1 className="title">Day 2: Entebbe – Kibale Forest National Park</h1>
            <p className="text">Depart early with your guide from Entebbe and travel through central Uganda to the picturesque Crater Lakes region. This is the gateway to Kibale Forest National Park, a protected tropical rainforest that has the highest concentration and variety of primates in all of East Africa. The park is home to 13 different primates as well as the bushpig, duiker, otter, and large herds of elephants that migrate between Kibale and Queen Elizabeth National Park. The bird life is also outstanding.</p>

            <p className="text">Arriving at the lodge in the afternoon, you can simply relax or go for a nature walk in the Bigodi Wetlands sanctuary, a local community initiative that is home to 8 different primate species, including black-and white colobus and red colobus, over 200 bird species and numerous other small mammals and reptiles.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Activities</b> : Transfer to Kibale and optional activities</p>
                <p><b>Comfort</b> : Chimpanzee Forest Guest House</p>
                <p><b>Premium</b> :  Papaya Lake Lodge</p>
            </div>
        </section>
    );
}
 
export default DayTwo;