const DayThree = () => {
    return (
        <section id="day3" className="package-detail tabcontent">
            <h1 className="title">Day 3: Kibale Forest Chimp Tracking and Bigodi Swamp Walk</h1>

            <p className="text">This morning, you will go for Chimpanzee tracking along the network of forest trails. Seeing habituated chimps squabbling and swinging from the trees is an exhilarating experience. Spend the morning in the forest returning to the lodge in time for lunch.</p>

            <p className="text">Later in the evening, you will have the opportunity of a guided walk through Bigodi Swamp. Bigodi Wetland Sanctuary is managed by the Kibale Association for Rural and Environmental organization formed in 1992 with the aim of achieving social and economic development for local communities. This swamp is a home to approximately 137 bird species and it’s also rich in vegetation, such as wild palms, polita figs and the dominant papyrus.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Kibale Guest Cottages</p>
                <p><b>Premium</b> : Primate Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayThree;