const Rwanda = () => {
    return (
                
        <section id="rwanda" className="wrapper child-page country">

            <div className="overview side-page">

                <h1 className="medium-title">Rwanda</h1>
                <p className="subtitle">Land Of A Thousand Hills</p>

                <div className="country-image">
                    <div className="c-div">
                        <div className="info">
                            <h3 className="title">Kigali</h3>
                            <p className="text">Capital City of Rwanda</p>
                        </div>
                    </div>
                    <div className="c-div">
                        <div className="info">
                            <h3 className="title">Rwanda</h3>
                            <p className="text">Land Of a Thousand Hills</p>
                        </div>
                    </div>
                    <div className="c-div">
                        <div className="info">
                            <h3 className="title">Mountain Gorilla</h3>
                            <p className="text">Volcanoes National Park</p>
                        </div>
                    </div>
                </div>

                <h3 className="title">Overview</h3>
                
                <p className="text">Rwanda is located in East- Central Africa, bordered by Uganda, Tanzania, Burundi, and the Democratic Republic of the Congo. Rwanda is in the African Great Lakes region and is highly elevated; its geography is dominated by mountains in the west and savanna to the east, with numerous lakes throughout the country. The climate is temperate to subtropical, with two rainy seasons and two dry seasons each year.</p>

                <p className="text">“The Land of a thousand hills,” as it is often called has a lot to offer to the discerning traveler. You will not only marvel at the magnificent gorillas in the Volcanoes National Park but also at the beautiful scenary, the richness of the culture of its people, their warmth and the country’s freshness and deep blue skies!</p>

                <p className="text">Catch a boat ride on Lake Kivu, engage in chimp tracking at Nyungwe forest National Park, see the golden monkeys, dance with the locals, and listen to their history at the genocide museum! Experience, explore!</p>
            </div>

            <aside className="side-menu facts">

                <div className="details-div">
                    <h3 className="title">Fast Facts</h3>
                    <p><b>Capital</b>: Kigali</p>
                    <p><b>Population</b>: 11.2 million </p>
                    <p><b>Size</b>: 26,338 sq km (10,169 sq miles) </p>

                    <ul className="list">
                        <p><b>Major Languages</b>:</p>
                        <li>Kinyarwanda (official)</li>
                        <li>French (official)</li>
                        <li>English (official)</li>
                        <li>Swahili</li>
                    </ul>

                    <ul className="list">
                        <p><b>Major Religions:</b></p>
                        <li>Christianity</li>
                        <li>indigenous beliefs</li>
                    </ul>

                    <p><b>Currency</b>: Rwandan franc</p>
                </div>

                <div className="details-div">
                    <h3 className="title">Safari Highlights</h3>
                    <p>Gorilla tracking</p>
                    <p>Game drives</p>
                    <p>Chimp tracking</p>
                    <p>Canopy Walk</p>
                    <p>Golden Monkey tracking</p>
                    <p>Cultural experience</p>
                    <p>Boat cruise</p>
                    <p>Market visits</p>
                    <p>City Tour</p>
                </div>
            </aside>

        </section>
    );
}
 
export default Rwanda;