const DaySix = () => {
    return (
        <section id="day6" className="package-detail tabcontent">
            <h1 className="title">Day 6: Queen Elizabeth – Ishasha sector</h1>

            <p className="text">After breakfast, you will have a game drive as you depart Queen Elizabeth for the southern sector of Queen Elizabeth (Ishasha) famed for its tree climbing lions. Arrive at the lodge in time for lunch. Relax in the afternoon and later go for an evening game drive.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation of choice</p>
                <p><b>Comfort</b> : Ishasha Jungle Lodge</p>
                <p><b>Premium</b> : Ishasha Wilderness Camp</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DaySix;