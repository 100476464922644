import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

const RW3D_GR_TR = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'}
    ];

    return (
        
        <main id="rw3d-gr-tr" className="page single-package">

            <BannerArea 
                title="Rwanda 3 days Gorilla tracking itinerary" 
                location="Volcanoes National Park" 
                days="3" 
                nights="2" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <section id="day1" className="package-detail tabcontent">
                    <h1 className="title">Day 1: Arrival - Volcanoes National Park</h1>
                    <p className="text">On arrival at the airport in the morning, a representative from Hadfar Safaris will warmly welcome you. Have a city tour, visiting the Genocide Museum and if time allows, the caplaki centre. Have lunch in Kigali. Later, continue towards Volcanoes National Park.</p>
                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p>Overnight at your lodge on FB</p>
                        <p><b>Comfort</b> : Da Vinci Lodge</p>
                        <p><b>Premium</b> : Five Volcanoes Boutique Hotel</p>
                    </div>
                </section>

                <section id="day2" className="package-detail tabcontent">
                    <h1 className="title">Day 2: Volcanoes National Park</h1>
                    <p className="text">As the morning twilight brightens the sky through the rolling mist on the summits of Muhabura, Bisoke , Karisimbi, Bisoke, Sabyinyo, Gahinga, we make our way into the Volcanoes National Park. Hiking through the thick, verdant, rainforest to eventually come across the gorillas in their natural habitat can take several hours of challenging walking in wet and muddy conditions but it is an amazing and unforgettable experience and well worth the effort!</p>
                    <p className="text">Lunch, dinner at your lodge, <b>Overnight at your lodge on FB</b></p>
                    <div className="details">
                        <h3 className="small-title">Details</h3>
                        <p>Activities of the day: GORILLA TRACKING</p>
                        <p><b>Comfort</b> : Da Vinci Lodge</p>
                        <p><b>Premium</b> : Five Volcanoes Boutique Hotel</p>
                    </div>
                </section>

                <section id="day3" className="package-detail tabcontent">
                    <h1 className="title">Day 3: Volcanoes National Park – Kigali/Fly Out</h1>
                    <p className="text">Enjoy during the early morning hours the peaceful atmosphere of the volcanoes. Return to Kigali. Lunch and airport transfer.</p>

                    <div className="details">
                        <h3 className="small-title">Cost inclusions :</h3>
                        <ul>
                            <li>All meals and accommodation as per the itinerary</li>
                            <li>All ground transportation in a comfortable 4x4 vehicle</li>
                            <li>Park entrance fees</li>
                            <li>Services of a full time English speaking driver/guide</li>
                            <li>Water while on safari</li>
                            <li>All airport pick-ups and drops</li>
                            <li>All activities as stipulated in the itinerary</li>
                            <li>All government taxes/levies</li>
                        </ul>
                    </div>

                    <div className="details">
                        <h3 className="small-title">Excluded are :</h3>
                        <ul>
                            <li>All optional activities or forest walks</li>
                            <li>Phone calls and bar bills</li>
                            <li>Personal insurance</li>
                            <li>Laundry</li>
                            <li>Air fare</li>
                            <li>All other services of a personal nature</li>
                            <li>Driver guide’s gratuity</li>
                        </ul>
                    </div>

                </section>

                <aside className="side-menu">

                    <Quote package_name="Rwanda 3 days Gorilla Tracking"/>

                </aside>

            </section>

        </main>
    );

}

export default RW3D_GR_TR;