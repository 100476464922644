const DayEleven = () => {
    return (
        <section id="day11" className="package-detail tabcontent">
            <h1 className="title">Day 11: Queen Elizabeth National park - Fort Portal</h1>

            <p className="text"><b>Activities of the day: Morning boat trip on Kazinga channel and afternoon drive to Fort portal and have an evening walk here.</b></p>

            <p className="text">In morning, you will take a cruise along the Kazinga Channel, home to one of the largest concentrations of hippopotamus, as well as herds of elephants, buffalo, and other wildlife that come to drink from the river. The Kazinga also attracts a tremendous variety of bird species including the African Eagle, Open-Billed Stork, and Pied Kingfishers.</p>
            
            <p className="text">Later drive to Fort portal and overnight at Fort motel.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayEleven;