const DayFive = () => {
    return (
        <section id="day5" className="package-detail tabcontent">
            <h1 className="title">Day 5: Queen - Bwindi Impenetrable National Park</h1>
            <p className="text">Today, you will transfer to <b>Bwindi</b> – passing via Ishasha, the southern sector of Queen Elizabeth National Park. Ishasha is famous for its tree-climbing lions and you can hope to see them.</p>

            <p className="text">Afterwards continue to Bwindi Impenetrable National Park. This dense equatorial jungle is accessible only on foot and features one of the richest ecosystems in Africa, including 120 species of mammals, 350 bird species including hornbills and turacos, 220 butterfly species, 27 frog species as well as chameleons, geckos and many other endangered species. It also boasts over 1,000 different flowering plants, ferns & trees.</p>

            <p className="text">Check into your lodge and enjoy the beautiful scenery of your remote surroundings.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your lodge</p>
                <p><b>Comfort</b> : Buhoma Haven Lodge</p>
                <p><b>Premium</b> : Mahogany Springs Lodge</p>
            </div>
        </section>
    );
}
 
export default DayFive;