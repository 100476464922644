import { useEffect, useState } from 'react';
import useTabs from "../js/useTabs";

const DayNav = ({daylist}) => {

    const [days, setDays] = useState(null);
    useEffect(() => {
        setDays(daylist);
    }, [daylist]);

    const HandleTabs = (tab_id) => {
        useTabs(tab_id);
    };

    (()=>{
        setTimeout(()=>{ document.querySelector("#day1btn").click(); }, 100);
    })();

    return (

        <section className="day-nav">

            { days && <ul className="day-list wrapper">
                {/* Using the days variable along with logical and (&&) helps negate the errors of the initial null value on the day variable */}
                
                { days.map((day) => ( <li id={day.dayBtn} className="tab-links" onClick={()=>HandleTabs(day.dayElement)} key={day.id}>{day.text}</li> )) }
                
            </ul> }

        </section>
    );

}

export default DayNav;