const DayEight = () => {
    return (
        <section id="day8" className="package-detail tabcontent">
            <h1 className="title">Day 8: Ishasha – Bwindi Impenetrable National Park</h1>
            <p className="text">After breakfast, you will have a game drive in search of the tree climbing lions and later depart Ishasha for Bwindi impenetrable National Park. Bwindi holds half of the world’s mountain gorillas having more than 10 families of the habituated gorillas and a lot of birds to those who are birders. Relax after lunch.</p>
            
            <p className="text">You will need to have a good night’s rest to get ready for the following day’s gorilla tracking.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your accommodation</p>
                <p><b>Comfort</b> : Buhoma haven Lodge</p>
                <p><b>Premium</b> : Mahogany Springs Lodge</p>
            </div>

        </section>
    );
}
 
export default DayEight;