const DaySeven = () => {
    return (
        <section id="day7" className="package-detail tabcontent">
            <h1 className="title">Day 7: Queen Elizabeth – Ishasha sector</h1>

            <p className="text">After breakfast, you will have a game drive as you depart Queen Elizabeth for the southern sector of Queen Elizabeth (Ishasha) famed for the tree climbing lions arriving at the camp in time for lunch. Relax in the afternoon and later go for an evening game drive and overnight at your accommodation</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Ishasha Jungle Lodge</p>
                <p><b>Premium</b> : Ishasha Wilderness Camp</p>
            </div>
        </section>
    );
}
 
export default DaySeven;