const useTabs = (tab_id) => {
    var i, tabcontent, tablinks;
    
    tablinks = document.querySelectorAll(".tab-links");

    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }

    document.getElementById(tab_id).style.display = "block";
    
    Array.from(tablinks).forEach((link)=>{
        
        link.addEventListener('click', (evt)=>{

            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].classList.remove("active");
            }

            const daybtn = evt.currentTarget;
            
            if (daybtn.classList.contains("active")) {
                daybtn.classList.remove("active");
            } else {
                daybtn.classList.add("active");
            }

        });
        
    });
}

export default useTabs;