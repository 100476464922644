const DayEight = () => {
    return (
        <section id="day8" className="package-detail tabcontent">
            <h1 className="title">Day 8: Fly out</h1>
            <p className="text">Depending on your flight, you can have a city tour and explore Kigali city by visiting the Genocide Museum and the Caplaki Crafts Center and later transfer to the airport to catch your flight home.</p>

            <div className="details">
                <h3 className="small-title">Cost inclusions :</h3>
                <ul>
                    <li>Gorilla permit</li>
                    <li>All meals and accommodation as per the itinerary</li>
                    <li>All ground transportation in a comfortable 4x4 vehicle</li>
                    <li>Park entrance fees</li>
                    <li>Services of a full time English speaking driver/guide</li>
                    <li>Water while on safari</li>
                    <li>All boat trips</li>
                    <li>All game drives</li>
                    <li>All airport pick-ups and drops</li>
                    <li>All activities as stipulated in the itinerary</li>
                    <li>All government taxes/levies</li>
                </ul>
            </div>

            <div className="details">
                <h3 className="small-title">Excluded are :</h3>
                <ul>
                    <li>Optional activities if requested for</li>
                    <li>Phone calls and bar bills</li>
                    <li>Balloon safari</li>
                    <li>Personal insurance</li>
                    <li>Laundry</li>
                    <li>Air fare</li>
                    <li>All other services of a personal nature</li>
                    <li>Driver guide’s gratuity</li>
                    <li>A day room if requested on the last day</li>
                </ul>
            </div>

        </section>
    );
}
 
export default DayEight;