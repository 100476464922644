import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./S17D_INT/DayOne";
import DayTwo from "./S17D_INT/DayTwo";
import DayThree from "./S17D_INT/DayThree";
import DayFour from "./S17D_INT/DayFour";
import DayFive from "./S17D_INT/DayFive";
import DaySix from "./S17D_INT/DaySix";
import DaySeven from "./S17D_INT/DaySeven";
import DayEight from "./S17D_INT/DayEight";
import DayNine from "./S17D_INT/DayNine";
import DayTen from "./S17D_INT/DayTen";
import DayEleven from "./S17D_INT/DayEleven";
import DayTwelve from "./S17D_INT/DayTwelve";
import DayThirteen from "./S17D_INT/DayThirteen";
import DayFourteen from "./S17D_INT/DayFourteen";
import DayFifteen from "./S17D_INT/DayFifteen";
import DaySixteen from "./S17D_INT/DaySixteen";
import DaySeventeen from "./S17D_INT/DaySeventeen";

const S17D_INT = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'},
        {id: 7, dayElement: 'day7', dayBtn: 'day7btn', text: 'day 7'},
        {id: 8, dayElement: 'day8', dayBtn: 'day8btn', text: 'day 8'},
        {id: 9, dayElement: 'day9', dayBtn: 'day9btn', text: 'day 9'},
        {id: 10, dayElement: 'day10', dayBtn: 'day10btn', text: 'day 10'},
        {id: 11, dayElement: 'day11', dayBtn: 'day11btn', text: 'day 11'},
        {id: 12, dayElement: 'day12', dayBtn: 'day12btn', text: 'day 12'},
        {id: 13, dayElement: 'day13', dayBtn: 'day13btn', text: 'day 13'},
        {id: 14, dayElement: 'day14', dayBtn: 'day14btn', text: 'day 14'},
        {id: 15, dayElement: 'day15', dayBtn: 'day15btn', text: 'day 15'},
        {id: 16, dayElement: 'day16', dayBtn: 'day16btn', text: 'day 16'},
        {id: 17, dayElement: 'day17', dayBtn: 'day17btn', text: 'day 17'}
    ];

    return (

        <main id="s17d-int" className="page single-package">

            <BannerArea 
                title="Seventeen Days Intrepid" 
                location="Rwanda and Uganda" 
                days="17" 
                nights="16" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <DaySeven />

                <DayEight />

                <DayNine />

                <DayTen />

                <DayEleven />

                <DayTwelve />

                <DayThirteen />

                <DayFourteen />

                <DayFifteen />

                <DaySixteen />

                <DaySeventeen />

                <aside className="side-menu">

                    <Quote package_name="Rwanda and Uganda Seventeen Days Intrepid"/>
                    
                </aside>

            </section>

        </main>
    );

}

export default S17D_INT;