const DayFifteen = () => {
    return (
        <section id="day15" className="package-detail tabcontent">
            <h1 className="title">Day 15: Murchison Falls</h1>

            <p className="text"><b>Activities of the Day: Breakfast, game drive and relaxation.</b></p>

            <p className="text">Today you will have breakfast and a game drive in the morning.</p>
            
            <p className="text">Later in the afternoon, you will relax.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                <p><b>Premium</b> : Paraa Safari Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DayFifteen;