const DayFour = () => {
    return (
        <section id="day4" className="package-detail tabcontent">
            <h1 className="title">Day 4: Ruhengeri</h1>
            <p className="text"><b>Activities of the day: Golden monkey tracking and an afternoon visit the Gorilla doctor.</b></p>

            <p className="text">Today you set off for golden monkey trekking in Parc des Volcans. Ascending the slopes of the Virungas, you will be immersed in the dense, lush rainforest. The vibrant birdcalls and squabbles of the golden monkeys await you. The golden monkey’s rich colours, energetic demeanour, and sizeable troupes of up to 30 individuals are truly a sight to behold.</p>

            <p className="text">In the afternoon, you will go and visit the gorilla doctors returning to the lodge in the evening.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b>: Mountain Gorilla View Lodge</p>
                <p><b>Premium</b>: Five Volcanoes Boutique Hotel</p>
                <p><b>Meal Plan</b>: FB</p>
            </div>
        </section>
    );
}
 
export default DayFour;