const DayFive = () => {
    return (
        <section id="day5" className="package-detail tabcontent">
            <h1 className="title">Day 5: Queen Elizabeth National Park</h1>
            <p className="text">This morning you will go for Chimpanzee tracking along the network of forest trails. Seeing chimps squabbling and swinging from the trees is an exhilarating experience. Spend the morning in the forest returning to the lodge in time for lunch.</p>

            <p className="text">In the afternoon, take a boat cruise along the Kazinga Channel. The 36km Kazinga Channel connects two Rift Valley lakes: Edward and George. These are named after Queen Victoria’s son and heir, Edward VII and his own successor, George V. Various animals come to the banks of the lakes to drink water. You will be able to see elephant, buffalo, hippos which puff and spray just few meters away from your boat, many birds including Great White and Pink-beaked Pelicans, Great and Long-Tailed Cormorants, Open-Billed Stork, African Jacana, Malachite and Pied Kingfishers among others.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Activities</b> : Morning chimp tracking & afternoon boat trip on Kazinga Channel.</p>
                <p><b>Comfort</b> : Kasenyi Safari Camp</p>
                <p><b>Premium</b> : Mweya Safari Lodge</p>
                <p><b>Meal Plan</b> : Breakfast, Lunch and Dinner</p>
            </div>
        </section>
    );
}
 
export default DayFive;