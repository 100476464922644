const DayFour = () => {
    return (
        <section id="day4" className="package-detail tabcontent">
            <h1 className="title">Day 4: Queen Elizabeth National Park</h1>
            <p className="text">This morning, you will go for a game drive in Queen Elizabeth National Park. This is an enormous 764 square mile (1,978 square km) wildlife sanctuary and is the second largest in Uganda. It extends from Lake George to Lake Edward, with the Kazinga Channel connecting these two great lakes. The park is renowned for its extensive game viewing, and you can hope to see large herds of buffalo, elephant, Uganda kob, lion and leopard, baboon, vervet monkey, waterhog, waterbuck and the elusive giant forest hog.</p>
            <p className="text">In the afternoon, enjoy a boat cruise along the <b>Kazinga Channel</b>, home to one of the largest concentrations of hippopotamus, as well as herds of elephants, buffalo, and other wildlife that come to drink from the river. The Kazinga also attracts a tremendous variety of bird species including the African Eagle, Open-Billed Stork, and Pied Kingfishers.</p>
            <p>Return to your lodge and relax.</p>
            <div className="details">
                <h3 className="small-title">Details</h3>
                <p className="text">Overnight at your lodge</p>
                <p><b>Activities:</b> Game drive and an afternoon boat cruise on the Kazinga Channel</p>
                <p><b>Comfort : </b>Kasenyi Safari Camp</p>
                <p><b>Premium : </b>Katara Lodge</p>
            </div>
        </section>
    );
}
 
export default DayFour;