import { Link } from 'react-router-dom';
import Banner from './partials/Banner';

const TourPackages = () => {

    const packages_route = "/tour-packages";

    return (
        <main id="tour-packages" className="page">

            <Banner 
                title="Enjoy every step of your tour" 
                subtile="We offer some of the best tour packages in the industry. Book with us and you will get the best packages with the value of your money" 
            />

            <section className="section">
                
                <div className="wrapper tours">
                    <div className="tour-link">
                            <h1 className="title">
                                {/* <Link to={packages_route+"/rwanda-3-days-gorilla-tracking"}>Rwanda 3 days Gorilla Tracking</Link> */}
                                <Link to={packages_route+"/rwanda-3-days-gorilla-tracking"}>Rwanda 3 days Gorilla Tracking</Link>
                            </h1>
                        <p className="text">Volcanoes National Park</p>
                    </div>

                    <div className="tour-link">
                            <h1 className="title">
                                <Link to={packages_route+"/uganda-8-days-great-lakes-and-the-chimpazees"}>Uganda 8 Days Great Lakes And The Chimpazees</Link>
                            </h1>
                        <p className="text">Kibale Forest National Park</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/rwanda-uganda-3-days-gorilla-express"}>Three Days Gorilla Express</Link>
                        </h1>
                        <p className="text">Rwanda and Uganda</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/uganda-6-days-5-nights-bwindi-and-queen"}>Six Days Bwindi and Queen</Link>
                        </h1>
                        <p className="text">Uganda</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/uganda-10-days"}>Uganda 10 days tour</Link>
                        </h1>
                        <p className="text">Variety in Uganda</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/beautiful-sunshine"}>Beautiful Sunshine</Link>
                        </h1>
                        <p className="text">Rwanda and Uganda</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/secrets-of-uganda-and-rwanda"}>Secrets of Uganda and Rwanda</Link>
                        </h1>
                        <p className="text">Rwanda and Uganda</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/murchison-falls-safari"}>Murchison Falls Safari</Link>
                        </h1>
                        <p className="text">Murchison Falls National Park</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/taste-of-uganda"}>Taste Of Uganda</Link>
                        </h1>
                        <p className="text">Variety in Uganda</p>
                    </div>

                    <div className="tour-link">
                        <h1 className="title">
                            <Link to={packages_route+"/17-days-intrepid"}>Seventeen Days Intrepid</Link>
                        </h1>
                        <p className="text">Rwanda and Uganda</p>
                    </div>
                </div>

            </section>
        </main>
    );

}

export default TourPackages;