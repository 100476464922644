import BannerArea from "../partials/BannerArea";
import DayNav from "../partials/DayNav";
import Quote from "../partials/Quote";

import DayOne from "./SEC_OF_UG_RW/DayOne";
import DayTwo from "./SEC_OF_UG_RW/DayTwo";
import DayThree from "./SEC_OF_UG_RW/DayThree";
import DayFour from "./SEC_OF_UG_RW/DayFour";
import DayFive from "./SEC_OF_UG_RW/DayFive";
import DaySix from "./SEC_OF_UG_RW/DaySix";
import DaySeven from "./SEC_OF_UG_RW/DaySeven";
import DayEight from "./SEC_OF_UG_RW/DayEight";
import DayNine from "./SEC_OF_UG_RW/DayNine";
import DayTen from "./SEC_OF_UG_RW/DayTen";
import DayEleven from "./SEC_OF_UG_RW/DayEleven";
import DayTwelve from "./SEC_OF_UG_RW/DayTwelve";
import DayThirteen from "./SEC_OF_UG_RW/DayThirteen";

const SEC_OF_UG_RW = () => {

    const days = [
        {id: 1, dayElement: 'day1', dayBtn: 'day1btn', text: 'day 1'},
        {id: 2, dayElement: 'day2', dayBtn: 'day2btn', text: 'day 2'},
        {id: 3, dayElement: 'day3', dayBtn: 'day3btn', text: 'day 3'},
        {id: 4, dayElement: 'day4', dayBtn: 'day4btn', text: 'day 4'},
        {id: 5, dayElement: 'day5', dayBtn: 'day5btn', text: 'day 5'},
        {id: 6, dayElement: 'day6', dayBtn: 'day6btn', text: 'day 6'},
        {id: 7, dayElement: 'day7', dayBtn: 'day7btn', text: 'day 7'},
        {id: 8, dayElement: 'day8', dayBtn: 'day8btn', text: 'day 8'},
        {id: 9, dayElement: 'day9', dayBtn: 'day9btn', text: 'day 9'},
        {id: 10, dayElement: 'day10', dayBtn: 'day10btn', text: 'day 10'},
        {id: 11, dayElement: 'day11', dayBtn: 'day11btn', text: 'day 11'},
        {id: 12, dayElement: 'day12', dayBtn: 'day12btn', text: 'day 12'},
        {id: 13, dayElement: 'day13', dayBtn: 'day13btn', text: 'day 13'}
    ];

    return (

        <main id="sec-of-ug-rw" className="page single-package">

            <BannerArea 
                title="Secrets of Uganda and Rwanda" 
                location="Uganda and Rwanda" 
                days="13" 
                nights="12" 
            />

            <DayNav daylist={days} />

            <section className="wrapper child-page">

                <DayOne />

                <DayTwo />

                <DayThree />

                <DayFour />

                <DayFive />

                <DaySix />

                <DaySeven />

                <DayEight />

                <DayNine />

                <DayTen />

                <DayEleven />

                <DayTwelve />

                <DayThirteen />

                <aside className="side-menu">

                    <Quote package_name="Secrets of Uganda and Rwanda"/>
                    
                </aside>

            </section>

        </main>
    );

}

export default SEC_OF_UG_RW;