const DayFour = () => {
    return (
        <section id="day4" className="package-detail tabcontent">
            <h1 className="title">Day 4: Murchison Falls National Park - Kibale National Park</h1>
            <p className="text"><b>Activities of the day: Breakfast and transfer to Kibale</b></p>
            <p className="text">This morning you will have breakfast and then proceed to Kibale forest for two nights. Kibale is home to an extraordinary thirteen species of primates, including chimpanzees, red colobus, and L’Hoest monkeys. Kibale National Forest has also recorded over 355 bird species, and a range of other mammals including the elusive forest elephant. You can engage in optional activities or simply relax at your lodge.</p>

            <p className="text"><b>Other optional Activities:</b></p>
            <p className="text"> Crater Lakes Hike: Formed by ancient volcanic activity, the crater lakes of Uganda are located near Fort Portal, and are a great hiking option. Typical hikes take you through local villages and tea plantations, to the base of several of these incredibly serene and beautiful lakes. With the Rwenzori Mountains in the background, this is by far one of Uganda’s most peaceful and extraordinary sights.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Comfort</b>: Chimpanzee Forest Guest House</p>
                <p><b>Premium</b>: Primate Lodge</p>
            </div>
        </section>
    );
}
 
export default DayFour;