import { Link } from 'react-router-dom';

const Navbar = () => {

    function displayMenu() {
        let menu = document.querySelector(".menu");
        menu.classList.toggle('mobile-menu');
    }

    return (
        <header>
            <nav className="wrapper">
                
                <Link to="/" className="logo">
                    <img src="/Ssebuliba-Paul-Hadfar-Safari-Logo-500px.png" alt=""></img>
                    <div>
                        <h1 className="title">Hadfar Safaris</h1>
                        <p className="desc">Next Stop, Adventure</p>
                    </div>
                </Link>

                <div className="menu">
                    <ul className="drop-down-menu">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/destinations">destinations</Link></li>
                        <li><Link to="/tour-packages">our tours</Link></li>
                        {/* <li><Link to="/blogs">Blog</Link></li> */}
                    </ul>

                    <Link to="/contact-us" className="special-link">contact</Link>
                </div>

                <button className="menu-btn" onClick={displayMenu}><i className="icon icon-menu-3"></i></button>

            </nav>
    </header>
    );

}

export default Navbar;