const DaySixteen = () => {
    return (
        <section id="day16" className="package-detail tabcontent">
            <h1 className="title">Day 16: Murchison Falls - Entebe</h1>

            <p className="text"><b>Activities for the day: Breakfast, transfer to Kampala passing via Ziwa Rhino sanctuary.</b></p>

            <p className="text">After Breakfast, you will transfer to Kampala having a stop at Ziwa Rhino sanctuary. Uganda’s rhinos became extinct in the early 1980s, after a period of civil unrest and heavy poaching. Ziwa was established over ten years ago to breed black and white rhinos, and restore the populations in Uganda’s National Parks. Ziwa offers visitors the rare opportunity to take a walking safari with the rhinos and be only mere feet from them!</p>
            
            <p className="text">After lunch you will continue on to Kampala arriving in the evening.</p>

            <div className="details">
                <h3 className="small-title">Details</h3>
                <p><b>Duration</b>: Approx. 8 Hrs</p>
                <p><b>Comfort</b> : Pakuba Safari Lodge</p>
                <p><b>Premium</b> : Paraa Safari Lodge</p>
                <p><b>Meal Plan</b> : FB</p>
            </div>
        </section>
    );
}
 
export default DaySixteen;