import { Link } from 'react-router-dom';

const Footer = () => {

    const SPDate = new Date();

    const company_name = "Hadfar Safaris";

    return (
        <footer className="site-footer">
            <div className="wrapper spaced">

                <div className="social-links">
                    <h6 className='text'>Let us be social</h6>
                    <ul className="link-icons">
                        <li className='text'>
                            <a href="https://www.facebook.com/Hadfar-safaris-104370547932288/" title="Hadfar on Facebook" className="link">
                                <i className="icon icon-facebook-7" />
                            </a>
                        </li>
                        {/* <li className='text'>
                            <a href="https://www.instagram.com" title="Hadfar on Instagram" className="link">
                                <i className="icon icon-instagram-5" />
                            </a>
                        </li> */}
                    </ul>
                </div>

                <div className="information-item">
                    <p className="text">Copyrights &copy; 2019 - {SPDate.getFullYear()} <Link to="/" className="link">{ company_name }</Link> | All rights reserved</p>
                </div>
                
            </div>
        </footer>
    );

}

export default Footer;